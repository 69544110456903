import React from 'react';

const AboutUs = () => {
  return (
    <div>
      <h1>About Us</h1>
      <p>Welcome to our website! We are dedicated to providing the best service...</p>
    </div>
  );
};

export default AboutUs;
