// client/src/AppContent.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import CollectionDetails from './pages/CollectionDetails';
import ItemDetails from './pages/ItemDetails';
import Portfolio from './pages/Portfolio';
import AboutUs from './pages/AboutUs';
import Inscriptions from './pages/Inscriptions';
import './styles/App.css';
import { FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import axios from 'axios';

function AppContent() {
  const navigate = useNavigate();
  const [btcPrice, setBtcPrice] = useState(null);
  const [totalInscriptions, setTotalInscriptions] = useState(null);
  const [totalOrdinalsVolume, setTotalOrdinalsVolume] = useState(null);
  const [totalTrades, setTotalTrades] = useState(null);
  const handleNavigate = (path) => {
    navigate(path);
  };




  useEffect(() => {
    const fetchBtcPrice = async () => {
      try {
        const priceResponse = await axios.get("https://mempool.space/api/v1/prices");
        setBtcPrice(priceResponse.data.USD);
      } catch (error) {
        console.error('Error fetching BTC price:', error);
      }
    };

    const fetchTotalInscriptions = async () => {
      try {
        const response = await axios.get("http://185.208.172.5:4000/api/global_info", {
          headers: {
            accept: "application/json",
            "x-api-key": "f37b0a38-8057-413e-9753-6c491af4d845",
          },
        });
        setTotalInscriptions(response.data.data[0].max_inscription_number);
      } catch (error) {
        console.error('Error fetching total inscriptions:', error);
      }
    };

    const fetchTotalOrdinalsVolume = async () => {
      try {
        const response = await axios.get("https://api.dune.com/api/v1/query/3417953/results?limit=1000", {
          headers: {
            "X-Dune-API-Key": "xAXR20KWiq3vBlXdviAlDz8tMlr3xtSi",
          },
        });
        setTotalOrdinalsVolume(response.data.result.rows[0].total);
      } catch (error) {
        console.error('Error fetching total ordinals volume:', error);
      }
    };

    const fetchTotalTrades = async () => {
      try {
        const response = await axios.get("https://api.dune.com/api/v1/query/2148993/results?limit=1000", {
          headers: {
            "X-Dune-API-Key": "xAXR20KWiq3vBlXdviAlDz8tMlr3xtSi",
          },
        });
        setTotalTrades(response.data.result.rows[0].trades);
      } catch (error) {
        console.error('Error fetching total ordinals volume:', error);
      }
    };


    fetchBtcPrice();
    fetchTotalInscriptions();
    fetchTotalOrdinalsVolume();
    fetchTotalTrades();
  }, []);


  return (
    <div className="app">
      <nav className="navbar">
        <div className="logo">
          <Link to="/">Ordinals Nexus</Link>
        </div>
        <div className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/collections">Collections</Link>
          <Link to="/inscriptions">Inscriptions</Link>
          <Link to="/about-us">About Us</Link>
        </div>
        <div className="social-icons">
          <a href="https://x.com/OrdinalsNexus" target="_blank" rel="noopener noreferrer"><FaXTwitter /></a>
          <a href="https://discord.gg/ordinalsnexus" target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
          <button onClick={() => handleNavigate('/portfolio')}>
            Launch App
          </button>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/collections/:symbol" element={<CollectionDetails />} />
        <Route path="/ordinals/item-details/:id" element={<ItemDetails />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/inscriptions" element={<Inscriptions />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>

      <div className="ticker-wrap">
        <div className="ticker">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="ticker-content">
              <span className="ticker-item">© 2024 Ordinals Nexus</span>
              <span className="ticker-dot">•</span>
              <span className="ticker-item">BTC Price: ${btcPrice ? btcPrice.toLocaleString() : 'Loading...'} USD</span>
              <span className="ticker-dot">•</span>
              <span className="ticker-item">Total Inscriptions: {totalInscriptions ? totalInscriptions.toLocaleString() : 'Loading...'}</span>
              <span className="ticker-dot">•</span>
              <span className="ticker-item">Total Ordinals Volume: {totalOrdinalsVolume ? `$${(totalOrdinalsVolume / 1e6).toFixed(0)} M` : 'Loading...'}</span>
              <span className="ticker-dot">•</span>
              <span className="ticker-item">
                Total Runes MarketCap: $1.18 B
              </span>
              <span className="ticker-dot">•</span>
              <span className="ticker-item">Total Trade: {totalTrades ? `${(totalTrades).toLocaleString()}` : 'Loading...'}</span>
              <span className="ticker-dot">•</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AppContent;