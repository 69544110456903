// client/src/pages/Home.js
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Treemap } from 'recharts';

const Home = () => {
  const [fees, setFees] = useState(null);
  const [btcPrice, setBtcPrice] = useState(null);
  const [historicalPrices, setHistoricalPrices] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [timeRange, setTimeRange] = useState('weekly');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmedBlocks, setConfirmedBlocks] = useState([]);
  const [mempoolBlocks, setMempoolBlocks] = useState([]);
  const [isLoadingBlocks, setIsLoadingBlocks] = useState(false);
  const scrollContainer = useRef(null);
  const [hasScrolledOnce, setHasScrolledOnce] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastBlockHeight, setLastBlockHeight] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedChain, setSelectedChain] = useState('bitcoin');
  const [collections, setCollections] = useState([]);
  const [timeFrame, setTimeFrame] = useState('10m');
  const [showRows, setShowRows] = useState(10);
  const [priceDisplay, setPriceDisplay] = useState('BTC');
  const [cryptoHeatmapData, setCryptoHeatmapData] = useState([]);
  const [heatmapLoading, setHeatmapLoading] = useState(true);
  const [hasRendered, setHasRendered] = useState(false);
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);
  const [activeTab, setActiveTab] = useState('top'); // 'top' or 'watchlist'
  const [watchlist, setWatchlist] = useState(JSON.parse(localStorage.getItem('nftWatchlist')) || []);

  // Add this useEffect to persist watchlist to localStorage
  useEffect(() => {
    localStorage.setItem('nftWatchlist', JSON.stringify(watchlist));
  }, [watchlist]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    // Get initial position
    setStartX(e.pageX);
    setScrollLeft(scrollContainer.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();

    // Calculate movement - exact 1:1 with mouse movement
    const dx = e.pageX - startX;
    scrollContainer.current.scrollLeft = scrollLeft - dx;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };



  const getTimeAgo = (timestamp) => {
    const minutes = Math.floor((Date.now() / 1000 - timestamp) / 60);
    if (minutes < 60) return `${minutes}m ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours}h ago`;
    const days = Math.floor(hours / 24);
    return `${days}d ago`;
  };



  // Utility functions
  const formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' B';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
    else return (bytes / 1048576).toFixed(2) + ' MB';
  };

  const formatFees = (sats) => {
    return (sats / 100000000).toFixed(3) + ' BTC';
  };

  const fetchMoreBlocks = useCallback(async () => {
    if (isLoadingMore || !lastBlockHeight) return;

    try {
      setIsLoadingMore(true);
      const response = await axios.get(`http://185.208.172.5:4000/proxy/mempool/v1/blocks/${lastBlockHeight}`);

      if (response.data && response.data.length > 0) {
        const lastBlock = response.data[response.data.length - 1];
        setLastBlockHeight(lastBlock.height - 1);
        setConfirmedBlocks(prev => [...prev, ...response.data]);
      }
    } catch (error) {
      console.error('Error fetching more blocks:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, lastBlockHeight]);


  useEffect(() => {
    const container = scrollContainer.current;
    if (!container) return;

    const handleScroll = () => {
      const scrollPosition = container.scrollLeft + container.clientWidth;
      const scrollThreshold = container.scrollWidth - 500;

      if (scrollPosition > scrollThreshold && !isLoadingMore) {
        fetchMoreBlocks();
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [isLoadingMore, fetchMoreBlocks]);


  // Add function to fetch both types of blocks
  const fetchAllBlocks = async () => {
    try {
      setIsLoadingBlocks(true);
      const [confirmedResponse, mempoolResponse] = await Promise.all([
        axios.get('http://185.208.172.5:4000/proxy/mempool/v1/blocks'),
        axios.get('http://185.208.172.5:4000/proxy/mempool/v1/fees/mempool-blocks')
      ]);

      const sortedMempoolBlocks = mempoolResponse.data.sort((b, a) => b.medianFee - a.medianFee);
      setMempoolBlocks(sortedMempoolBlocks);

      if (confirmedResponse.data.length > 0) {
        setConfirmedBlocks(confirmedResponse.data);
        // Set the last block height for pagination
        const lastBlock = confirmedResponse.data[confirmedResponse.data.length - 1];
        setLastBlockHeight(lastBlock.height - 1);
      }
    } catch (error) {
      console.error('Error fetching blocks:', error);
    } finally {
      setIsLoadingBlocks(false);
    }
  };

  useEffect(() => {
    if (!hasScrolledOnce && scrollContainer.current && mempoolBlocks.length > 0 && confirmedBlocks.length > 0) {
      setTimeout(() => {
        const blockWidth = 200;
        const gap = 15;
        const containerWidth = scrollContainer.current.clientWidth;

        // Position of highest fee unconfirmed block (last in the list)
        const unconfirmedPosition = mempoolBlocks.length * (blockWidth + gap);

        // Adjust the centering calculation
        // We want to show about 3 blocks on each side of our target blocks
        const targetPosition = unconfirmedPosition - (2 * (blockWidth + gap));
        const centeredPosition = targetPosition - (containerWidth / 2) + (3.5 * blockWidth);

        scrollContainer.current.scrollLeft = Math.max(0, centeredPosition);
        setHasScrolledOnce(true);
      }, 100);
    }
  }, [mempoolBlocks.length, confirmedBlocks.length, hasScrolledOnce]);

  useEffect(() => {
    fetchAllBlocks(); // Initial fetch
    const interval = setInterval(fetchAllBlocks, 10000); // Update every 10 seconds instead of 30

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []);





  const getApproximateTime = (index, totalBlocks) => {
    // Calculate reverse index since highest fee should have lowest time
    const reverseIndex = totalBlocks - index - 1;
    const minutes = 9 + (reverseIndex * 10);
    // Only return time if it's within our range (up to ~69 minutes)
    return minutes <= 69 ? `in ~${minutes} minutes` : null;
  };






  const fetchHistoricalPrices = useCallback(async () => {
    try {
      setIsLoading(true);
      const now = Math.floor(Date.now() / 1000);
      let startTime;
      let interval;
      let dataPoints;

      switch (timeRange) {
        case 'daily':
          startTime = now - (24 * 60 * 60);
          interval = 60 * 60;
          dataPoints = 24;
          break;
        case 'weekly':
          startTime = now - (7 * 24 * 60 * 60);
          interval = 6 * 60 * 60;
          dataPoints = 28;
          break;
        case 'monthly':
          startTime = now - (30 * 24 * 60 * 60);
          interval = 24 * 60 * 60;
          dataPoints = 30;
          break;
        default:
          startTime = now - (7 * 24 * 60 * 60);
          interval = 6 * 60 * 60;
          dataPoints = 28;
      }

      // Create all timestamps first
      const timestamps = Array.from({ length: dataPoints }, (_, i) =>
        startTime + (i * interval)
      );

      // Fetch all prices in parallel
      const pricePromises = timestamps.map(timestamp =>
        axios.get(`http://185.208.172.5:4000/proxy/mempool/v1/historical-price`, {
          params: {
            timestamp,
            currency: selectedCurrency
          }
        })
      );

      const responses = await Promise.all(pricePromises);
      const prices = responses
        .map((response, index) => {
          if (response.data?.prices?.[0]) {
            return {
              time: timestamps[index] * 1000,
              price: response.data.prices[0][selectedCurrency]
            };
          }
          return null;
        })
        .filter(price => price !== null);

      setHistoricalPrices(prices);
    } catch (error) {
      console.error('Error fetching historical prices:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedCurrency, timeRange]);

  useEffect(() => {
    fetchHistoricalPrices();
  }, [fetchHistoricalPrices]);

  useEffect(() => {
    const fetchFees = async () => {
      try {
        const feesResponse = await axios.get("http://185.208.172.5:4000/proxy/mempool/v1/fees/recommended");
        setFees(feesResponse.data);
        console.log('Fees Response:', feesResponse.data);

        const priceResponse = await axios.get("http://185.208.172.5:4000/proxy/mempool/prices");
        setBtcPrice(priceResponse.data.USD);
        console.log('BTC Price in USD:', priceResponse.data.USD);
      } catch (error) {
        console.error('Error fetching fees, BTC price, or recent transactions:', error);
      }
    };
    fetchFees();
    const interval = setInterval(fetchFees, 10000);
    return () => clearInterval(interval);
  }, []);

  const calculateFeeInUsd = (feeInSats) => {
    if (!btcPrice) return "-";
    const feeInBtc = feeInSats * 0.0000014;
    const feeInUsd = feeInBtc * btcPrice;
    return `$${feeInUsd.toFixed(2)}`;
  };



  // Move this before the useEffect for heatmap
  const formatNumber = (value, currency = '') => {
    if (!value || isNaN(value)) return '--';

    const num = parseFloat(value);
    const prefix = currency === 'USD' ? '$' : '';

    if (num >= 1000000) {
      const millions = (num / 1000000).toFixed(1);
      return `${prefix}${millions.endsWith('.0') ? millions.slice(0, -2) : millions}M`;
    }
    if (num >= 1000) {
      const thousands = (num / 1000).toFixed(1);
      return `${prefix}${thousands.endsWith('.0') ? thousands.slice(0, -2) : thousands}K`;
    }
    return `${prefix}${num.toFixed(1)}`;
  };


  useEffect(() => {
    const fetchHeatmapData = async () => {
      setHeatmapLoading(true);
      try {
        const response = await axios.get(
          'http://185.208.172.5:4000/proxy/coingecko/markets'
        );

        const totalMarketCap = response.data.reduce((sum, coin) => sum + coin.market_cap, 0);
        const formattedData = response.data
          .filter(coin => coin.market_cap && coin.price_change_percentage_24h)
          .map(coin => ({
            name: coin.symbol.toUpperCase(),
            size: coin.market_cap,
            value: Math.abs(coin.price_change_percentage_24h || 0),
            priceChange: coin.price_change_percentage_24h || 0,
            currentPrice: coin.current_price || 0,
            marketCap: coin.market_cap || 0,
            dominance: ((coin.market_cap / totalMarketCap) * 100).toFixed(1)
          }));

        setCryptoHeatmapData(formattedData);
      } catch (error) {
        console.error('Error fetching heatmap data:', error);
        setCryptoHeatmapData([]);
      } finally {
        setHeatmapLoading(false);
      }
    };

    fetchHeatmapData();
    const interval = setInterval(fetchHeatmapData, 60000);

    return () => clearInterval(interval);
  }, []);


  const getPriceChangeColor = (change) => {
    const absChange = Math.abs(change);
    if (change > 0) {
      if (absChange >= 10) return '#1B5E20';
      if (absChange >= 5) return '#2E7D32';
      if (absChange >= 2) return '#388E3C';
      return '#43A047';
    } else {
      if (absChange >= 10) return '#B71C1C';
      if (absChange >= 5) return '#C62828';
      if (absChange >= 2) return '#D32F2F';
      return '#E53935';
    }
  };


  const fetchCollections = useCallback(async (selectedTimeFrame) => {
    setIsLoadingCollections(true);
    try {
      const response = await axios.get(
        `http://185.208.172.5:4000/proxy/magiceden/collection_stats/search/bitcoin?sort=volume&direction=desc&offset=0&limit=100&window=${selectedTimeFrame}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer 40d02095-f917-4a2e-962f-cf2a69e50a65`,
          },
        }
      );

      let fetchedCollections = response.data.results || response.data || [];
      fetchedCollections = fetchedCollections.filter(collection =>
        collection.image && (
          !['10m', '1h', '6h'].includes(timeFrame) || collection.vol
        )
      );
      setCollections(fetchedCollections);
    } catch (error) {
      console.error('Error fetching collections:', error);
    } finally {
      setIsLoadingCollections(false);
    }
  }, [timeFrame]);

  useEffect(() => {
    fetchCollections(timeFrame);
  }, [timeFrame, fetchCollections]);







  const styles = {
    container: {
      padding: '40px',
      minHeight: '100vh',
      background: 'linear-gradient(180deg, #000814 0%, #001D3D 100%)',
    },
    header: {
      display: 'grid',
      marginTop: '40px',
      marginBottom: '60px',
      padding: '40px',
      borderRadius: '20px',
      background: 'rgba(0, 29, 61, 0.5)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 211, 10, 0.1)',
    },

    title: {
      fontSize: '3.5em',
      textAlign: 'center',
      background: 'linear-gradient(45deg, #FFD60A, #FFC300)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontWeight: 'bold',
    },
    subtitle: {
      display: 'unset',
      fontSize: '1.2em',
      color: '#FFD60A',
      opacity: 0.9,
      margin: '0 auto',
      maxWidth: '800px',
      textAlign: 'center',
      lineHeight: '1.6',
    },

    button: {
      padding: '15px 40px',
      margin: '30px auto 0',
      background: 'linear-gradient(45deg, #FFC300, #FFD60A)',
      border: 'none',
      borderRadius: '30px',
      color: '#000814',
      fontSize: '1.1em',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      boxShadow: '0 4px 15px rgba(255, 195, 0, 0.3)',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 6px 20px rgba(255, 195, 0, 0.4)',
      }
    },
    chartSection: {
      backgroundColor: '#001D3D',
      padding: '30px',
      borderRadius: '20px',
      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
      marginBottom: '30px',
      border: '1px solid rgba(255, 211, 10, 0.1)',
    },
    currencySelect: {
      padding: '8px 16px',
      backgroundColor: '#002952',
      color: '#FFD60A',
      border: '1px solid #FFD60A',
      borderRadius: '10px',
      cursor: 'pointer',
      fontSize: '0.9em',
    }



  };

  return (
    <div style={styles.container} >
      {/* Blocks Section */}
      <div style={{
        backgroundColor: '#001D3D',
        padding: '25px',
        borderRadius: '15px',
        marginBottom: '30px'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: '30px'
        }}>
          {/* Chain Selection Buttons */}
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              onClick={() => setSelectedChain('bitcoin')}
              style={{
                padding: '8px 16px',
                backgroundColor: selectedChain === 'bitcoin' ? '#FFC300' : 'rgba(0, 41, 82, 0.5)',
                color: selectedChain === 'bitcoin' ? '#000814' : '#FFD60A',
                border: `1px solid ${selectedChain === 'bitcoin' ? '#FFC300' : 'rgba(255, 211, 10, 0.1)'}`,
                borderRadius: '8px',
                cursor: 'pointer',
                fontSize: '0.95em',
                fontWeight: '500',
                transition: 'all 0.3s ease'
              }}
            >
              Bitcoin
            </button>
            <button
              disabled
              style={{
                padding: '8px 16px',
                backgroundColor: 'rgba(0, 41, 82, 0.5)',
                color: '#666',
                border: '1px solid rgba(255, 211, 10, 0.1)',
                borderRadius: '8px',
                cursor: 'not-allowed',
                fontSize: '0.95em',
                fontWeight: '500',
                opacity: 0.5
              }}
            >
              Ethereum
            </button>
            <button
              disabled
              style={{
                padding: '8px 16px',
                backgroundColor: 'rgba(0, 41, 82, 0.5)',
                color: '#666',
                border: '1px solid rgba(255, 211, 10, 0.1)',
                borderRadius: '8px',
                cursor: 'not-allowed',
                fontSize: '0.95em',
                fontWeight: '500',
                opacity: 0.5
              }}
            >
              Solana
            </button>
          </div>

          {/* Right side - Fee Cards */}
          {fees && btcPrice && (
            <div style={{
              display: 'flex',
              gap: '15px'
            }}>
              {/* No Priority */}
              <div style={{
                padding: '12px 20px',
                backgroundColor: 'rgba(0, 41, 82, 0.5)',
                borderRadius: '10px',
                border: '1px solid rgba(255, 0, 0, 0.1)',
                minWidth: '140px'
              }}>
                <div style={{ color: '#FF0000', fontSize: '0.9em', marginBottom: '4px' }}>No Priority</div>
                <div style={{ color: '#FFF', fontSize: '1.1em', marginBottom: '2px' }}>{fees.economyFee} sat/vB</div>
                <div style={{ color: '#FF0000', fontSize: '0.8em' }}>{calculateFeeInUsd(fees.economyFee)}</div>
              </div>

              {/* Low Priority */}
              <div style={{
                padding: '12px 20px',
                backgroundColor: 'rgba(0, 41, 82, 0.5)',
                borderRadius: '10px',
                border: '1px solid rgba(255, 0, 0, 0.1)',
                minWidth: '140px'
              }}>
                <div style={{ color: '#FF6B6B', fontSize: '0.9em', marginBottom: '4px' }}>Low Priority</div>
                <div style={{ color: '#FFF', fontSize: '1.1em', marginBottom: '2px' }}>{fees.hourFee} sat/vB</div>
                <div style={{ color: '#FF6B6B', fontSize: '0.8em' }}>{calculateFeeInUsd(fees.hourFee)}</div>
              </div>

              {/* Medium Priority */}
              <div style={{
                padding: '12px 20px',
                backgroundColor: 'rgba(0, 41, 82, 0.5)',
                borderRadius: '10px',
                border: '1px solid rgba(255, 214, 10, 0.1)',
                minWidth: '140px'
              }}>
                <div style={{ color: '#FFD60A', fontSize: '0.9em', marginBottom: '4px' }}>Medium Priority</div>
                <div style={{ color: '#FFF', fontSize: '1.1em', marginBottom: '2px' }}>{fees.halfHourFee} sat/vB</div>
                <div style={{ color: '#FFD60A', fontSize: '0.8em' }}>{calculateFeeInUsd(fees.halfHourFee)}</div>
              </div>

              {/* High Priority */}
              <div style={{
                padding: '12px 20px',
                backgroundColor: 'rgba(0, 41, 82, 0.5)',
                borderRadius: '10px',
                border: '1px solid rgba(76, 187, 23, 0.1)',
                minWidth: '140px'
              }}>
                <div style={{ color: '#4CBB17', fontSize: '0.9em', marginBottom: '4px' }}>High Priority</div>
                <div style={{ color: '#FFF', fontSize: '1.1em', marginBottom: '2px' }}>{fees.fastestFee} sat/vB</div>
                <div style={{ color: '#4CBB17', fontSize: '0.8em' }}>{calculateFeeInUsd(fees.fastestFee)}</div>
              </div>
            </div>
          )}
        </div>

        {isLoadingBlocks && confirmedBlocks.length === 0 ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '200px',
            color: '#FFD60A'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px'
            }}>
              <div style={{
                width: '40px',
                height: '40px',
                border: '3px solid #FFD60A',
                borderTop: '3px solid transparent',
                borderRadius: '50%',
                animation: 'spin 1s linear infinite'
              }} />
              <span>Loading blocks...</span>
            </div>
          </div>
        ) : (
          <div
            ref={scrollContainer}
            style={{
              display: 'flex',
              gap: '15px',
              overflowX: 'auto',
              padding: '10px 0',
              cursor: isDragging ? 'grabbing' : 'grab',
              userSelect: 'none'
            }}
            className="block-scroll"
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {/* Unconfirmed (Mempool) Blocks */}
            {mempoolBlocks.map((block, index) => (
              <div
                key={`mempool-${index}`}
                style={{
                  flex: '0 0 200px',
                  backgroundColor: 'rgba(47, 133, 90, 0.1)',
                  padding: '15px',
                  borderRadius: '10px',
                  border: '1px solid rgba(72, 187, 120, 0.2)',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  position: 'relative'
                }}
              >
                <div style={{ marginBottom: '10px' }}>
                  <div style={{ color: '#48BB78', fontSize: '0.9em' }}>Pending Block</div>
                  <div>
                    <div style={{ color: '#FFD60A', fontSize: '1.2em', fontWeight: 'bold' }}>
                      {block.medianFee.toFixed(0)} sat/vB
                    </div>
                    <div style={{ color: '#A0AEC0', fontSize: '0.75em' }}>
                      {block.feeRange[0].toFixed(0)} - {block.feeRange[block.feeRange.length - 1].toFixed(0)} sat/vB
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <div style={{ color: '#A0AEC0', fontSize: '0.8em' }}>Transactions</div>
                  <div style={{ color: '#FFFFFF' }}>~{block.nTx} txs</div>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <div style={{ color: '#A0AEC0', fontSize: '0.8em' }}>Size</div>
                  <div style={{ color: '#FFFFFF' }}>{formatBytes(block.blockSize)}</div>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <div style={{ color: '#A0AEC0', fontSize: '0.8em' }}>Total Fees</div>
                  <div style={{ color: '#48BB78' }}>{formatFees(block.totalFees)}</div>
                </div>

                {getApproximateTime(index, mempoolBlocks.length) && (
                  <div style={{
                    marginTop: '10px',
                    padding: '5px 10px',
                    backgroundColor: 'rgba(72, 187, 120, 0.1)',
                    borderRadius: '5px',
                    fontSize: '0.8em',
                    color: '#48BB78',
                    textAlign: 'center'
                  }}>
                    {getApproximateTime(index, mempoolBlocks.length)}
                  </div>
                )}

                <div style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  width: '8px',
                  height: '8px',
                  backgroundColor: '#48BB78',
                  borderRadius: '50%',
                  animation: 'pulse 2s infinite'
                }} />
              </div>
            ))}
            <div style={{ borderRight: '5px dashed' }}>
            </div>
            {/* Confirmed Blocks */}
            {confirmedBlocks.map((block) => (
              <div
                key={block.id}
                style={{
                  flex: '0 0 200px',
                  backgroundColor: 'rgba(66, 153, 225, 0.1)',
                  padding: '15px',
                  borderRadius: '10px',
                  border: '1px solid rgba(66, 153, 225, 0.2)',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease'
                }}
              >
                <div style={{ marginBottom: '10px' }}>
                  <div style={{ color: '#4299E1', fontSize: '0.9em' }}>Block #{block.height}</div>
                  <div>
                    <div style={{ color: '#FFD60A', fontSize: '1.2em', fontWeight: 'bold' }}>
                      {block.extras.medianFee.toFixed(0)} sat/vB
                    </div>
                    <div style={{ color: '#A0AEC0', fontSize: '0.75em' }}>
                      {block.extras.feeRange[0].toFixed(0)} - {block.extras.feeRange[block.extras.feeRange.length - 1].toFixed(0)} sat/vB
                    </div>
                  </div>
                </div>



                <div style={{ marginBottom: '5px' }}>
                  <div style={{ color: '#A0AEC0', fontSize: '0.8em' }}>Time</div>
                  <div style={{ color: '#FFFFFF' }}>{getTimeAgo(block.timestamp)}</div>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <div style={{ color: '#A0AEC0', fontSize: '0.8em' }}>Transactions</div>
                  <div style={{ color: '#FFFFFF' }}>{block.tx_count.toLocaleString()}</div>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <div style={{ color: '#A0AEC0', fontSize: '0.8em' }}>Total Fees</div>
                  <div style={{ color: '#4299E1' }}>
                    {(block.extras.totalFees / 100000000).toFixed(3)} BTC
                  </div>
                </div>

                {block.extras?.pool && (
                  <div style={{
                    marginTop: '10px',
                    padding: '5px 10px',
                    backgroundColor: 'rgba(66, 153, 225, 0.1)',
                    borderRadius: '5px',
                    fontSize: '0.8em',
                    color: '#4299E1',
                    textAlign: 'center'
                  }}>
                    {block.extras.pool.name}
                  </div>
                )}
              </div>
            ))}

            {isLoadingMore && (
              <div style={{
                flex: '0 0 200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px'
              }}>
                <div style={{
                  width: '30px',
                  height: '30px',
                  border: '3px solid rgba(66, 153, 225, 0.3)',
                  borderTop: '3px solid #4299E1',
                  borderRadius: '50%',
                  animation: 'spin 1s linear infinite'
                }} />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Add these styles for animations */}
      <style>
        {`
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(72, 187, 120, 0.4);
      }
      70% {
        box-shadow: 0 0 0 10px rgba(72, 187, 120, 0);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(72, 187, 120, 0);
      }
    }
  `}
      </style>

      {/* Add this style for the pulse animation */}
      <style>
        {`
    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(72, 187, 120, 0.4);
      }
      70% {
        box-shadow: 0 0 0 10px rgba(72, 187, 120, 0);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(72, 187, 120, 0);
      }
    }
  `}
      </style>
      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '60px' }}>
        {/* Bitcoin Price Chart */}
        <div style={{
          ...styles.chartSection,
          flex: '1 1 400px',
          minHeight: '500px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px'
          }}>
            <h2 style={{ color: '#FFD60A', fontSize: '1.8em' }}>Bitcoin Price Trends</h2>
            <div style={{ display: 'flex', gap: '10px' }}>
              <div style={{
                display: 'flex',
                backgroundColor: '#002952',
                borderRadius: '10px',
                padding: '4px'
              }}>
                {['daily', 'weekly', 'monthly'].map((range) => (
                  <button
                    key={range}
                    onClick={() => setTimeRange(range)}
                    style={{
                      padding: '8px 16px',
                      backgroundColor: timeRange === range ? '#FFC300' : 'transparent',
                      color: timeRange === range ? '#001D3D' : '#FFD60A',
                      border: 'none',
                      borderRadius: '8px',
                      cursor: 'pointer',
                      fontSize: '0.9em',
                      fontWeight: '500',
                      transition: 'all 0.3s ease',
                    }}
                  >
                    {range.charAt(0).toUpperCase() + range.slice(1)}
                  </button>
                ))}
              </div>
              <select
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
                style={styles.currencySelect}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </div>
          </div>

          {isLoading ? (
            <div style={{
              height: 400,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#FFD60A'
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}>
                <div style={{
                  width: '40px',
                  height: '40px',
                  border: '3px solid #FFD60A',
                  borderTop: '3px solid transparent',
                  borderRadius: '50%',
                  animation: 'spin 1s linear infinite'
                }} />
                <span>Loading price data...</span>
              </div>
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={500}>
              <LineChart
                data={historicalPrices}
                margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke="rgba(255, 211, 10, 0.05)"
                  vertical={false}
                />
                <XAxis
                  dataKey="time"
                  stroke="#FFD60A"
                  tickFormatter={time => {
                    const date = new Date(time);
                    switch (timeRange) {
                      case 'daily':
                        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                      case 'weekly':
                        return date.toLocaleDateString([], { weekday: 'short' });
                      case 'monthly':
                        return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
                      default:
                        return date.toLocaleDateString();
                    }
                  }}
                  tick={{ fontSize: 12, fill: '#FFD60A' }}
                  axisLine={{ stroke: 'rgba(255, 211, 10, 0.2)' }}
                  tickLine={{ stroke: 'rgba(255, 211, 10, 0.2)' }}
                  dy={10}
                />
                <YAxis
                  stroke="#FFD60A"
                  domain={['auto', 'auto']}
                  tickFormatter={value =>
                    `${selectedCurrency === 'USD' ? '$' : '€'}${Number(value).toLocaleString()}`
                  }
                  tick={{ fontSize: 12, fill: '#FFD60A' }}
                  axisLine={{ stroke: 'rgba(255, 211, 10, 0.2)' }}
                  tickLine={{ stroke: 'rgba(255, 211, 10, 0.2)' }}
                  width={80}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'rgba(0, 29, 61, 0.9)',
                    border: '1px solid rgba(255, 211, 10, 0.2)',
                    borderRadius: '8px',
                    padding: '12px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                  }}
                  labelFormatter={time => {
                    const date = new Date(time);
                    switch (timeRange) {
                      case 'daily':
                        return date.toLocaleString([], {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true
                        });
                      case 'weekly':
                        return date.toLocaleString([], {
                          weekday: 'long',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                        });
                      case 'monthly':
                        return date.toLocaleString([], {
                          month: 'long',
                          day: 'numeric'
                        });
                      default:
                        return date.toLocaleDateString();
                    }
                  }}
                  formatter={value => [
                    `${selectedCurrency === 'USD' ? '$' : '€'}${Number(value).toLocaleString()}`,
                    'Price'
                  ]}
                  labelStyle={{ color: '#FFD60A' }}
                  itemStyle={{ color: '#FFD60A' }}
                />
                <Line
                  type="monotone"
                  dataKey="price"
                  stroke="#FFC300"
                  strokeWidth={2}
                  dot={false}
                  activeDot={{
                    r: 6,
                    stroke: '#FFC300',
                    strokeWidth: 2,
                    fill: '#001D3D'
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>

        {/* Crypto Heatmap */}
        <div style={{
          ...styles.chartSection,
          flex: '1 1 400px',
          height: '600px',  // Fixed height
          maxHeight: '600px', // Maximum height
          overflow: 'hidden' // Prevent overflow
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: ''
          }}>
            <h2 style={{ color: '#FFD60A', fontSize: '1.8em' }}>Live Cryptocurrency Heatmap</h2>
          </div>
          {heatmapLoading && !cryptoHeatmapData.length ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              color: '#FFD60A'
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}>
                <div style={{
                  width: '40px',
                  height: '40px',
                  border: '3px solid #FFD60A',
                  borderTop: '3px solid transparent',
                  borderRadius: '50%',
                  animation: 'spin 1s linear infinite'
                }} />
                <span>Loading market data...</span>
              </div>
            </div>
          ) : cryptoHeatmapData.length > 0 ? (
            <ResponsiveContainer width="100%" height={500} onAnimationEnd={() => setHasRendered(true)}>
              <Treemap
                data={cryptoHeatmapData}
                dataKey="size"
                aspectRatio={21 / 9}
                style={{ overflow: 'hidden' }}
                isAnimationActive={!hasRendered} // Only animate on first render
                animationBegin={0}
                animationDuration={hasRendered ? 0 : 1000}
                content={({ x, y, width, height, name, priceChange = 0, dominance }) => {
                  if (!name || width < 1 || height < 1) return null;

                  const fontSize = Math.min(width / 4, height / 4, 24);
                  return (
                    <g>
                      <rect
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        style={{
                          fill: getPriceChangeColor(priceChange),
                        }}
                        stroke="#001D3D"
                        strokeWidth={1}
                      />
                      {width > 30 && height > 30 && (
                        <>
                          <text
                            x={x + width / 2}
                            y={y + height / 2 - fontSize / 2}
                            textAnchor="middle"
                            fill="#fff"
                            fontSize={fontSize}
                            fontWeight="bold"
                            style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}
                          >
                            {name}
                          </text>
                          <text
                            x={x + width / 2}
                            y={y + height / 2 + fontSize}
                            textAnchor="middle"
                            fill="#fff"
                            fontSize={fontSize * 0.8}
                            style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}
                          >
                            {priceChange >= 0 ? '+' : ''}{priceChange.toFixed(2)}%
                          </text>
                          <text
                            x={x + width / 2}
                            y={y + height - 10}
                            textAnchor="middle"
                            fill="#fff"
                            fontSize={fontSize * 0.6}
                            style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}
                          >
                            D:{dominance}%
                          </text>
                        </>
                      )}
                    </g>
                  );
                }}
              />
            </ResponsiveContainer>
          ) : null}

        </div>
      </div>


      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>

      <div style={{
        backgroundColor: '#001D3D',
        borderRadius: '15px',
        padding: '25px',
        marginTop: '30px',
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px'
          }}>
            <div style={{
              display: 'flex',
              gap: '10px'
            }}>
              <button
                onClick={() => setActiveTab('top')}
                style={{
                  padding: '10px 20px',
                  backgroundColor: activeTab === 'top' ? '#FFC300' : 'transparent',
                  color: activeTab === 'top' ? '#001D3D' : '#FFD60A',
                  border: activeTab === 'top' ? 'none' : '1px solid #FFD60A',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  fontSize: '1.2em',
                  fontWeight: '500',
                  transition: 'all 0.2s ease'
                }}
              >
                Top Ordinals NFTs
              </button>
              <button
                onClick={() => setActiveTab('watchlist')}
                style={{
                  padding: '10px 20px',
                  backgroundColor: activeTab === 'watchlist' ? '#FFC300' : 'transparent',
                  color: activeTab === 'watchlist' ? '#001D3D' : '#FFD60A',
                  border: activeTab === 'watchlist' ? 'none' : '1px solid #FFD60A',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  fontSize: '1.2em',
                  fontWeight: '500',
                  transition: 'all 0.2s ease'
                }}
              >
                Watchlist {watchlist.length > 0 && `(${watchlist.length})`}
              </button>
            </div>

            {/* Your existing filter buttons */}
            <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
              {/* ... price display and timeframe buttons ... */}
            </div>
          </div>

          <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>


            <div style={{
              display: 'flex',
              backgroundColor: '#002952',
              borderRadius: '8px',
              padding: '4px',
              gap: '4px'
            }}>
              {['10m', '1h', '6h', '1d', '7d', '30d'].map((frame) => (
                <button
                  key={frame}
                  onClick={() => setTimeFrame(frame)}
                  style={{
                    padding: '8px 16px',
                    backgroundColor: timeFrame === frame ? '#FFC300' : 'transparent',
                    color: timeFrame === frame ? '#001D3D' : '#FFD60A',
                    border: 'none',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '0.9em',
                    fontWeight: '500',
                    transition: 'all 0.2s ease'
                  }}
                >
                  {frame}
                </button>
              ))}
            </div>


            <div style={{
              display: 'flex',
              backgroundColor: '#002952',
              borderRadius: '8px',
              padding: '4px'
            }}>
              <button
                onClick={() => setPriceDisplay('BTC')}
                style={{
                  padding: '8px 16px',
                  backgroundColor: priceDisplay === 'BTC' ? '#FFC300' : 'transparent',
                  color: priceDisplay === 'BTC' ? '#001D3D' : '#FFD60A',
                  border: 'none',
                  borderRadius: '6px',
                  cursor: 'pointer',
                  fontSize: '0.9em',
                  fontWeight: '500',
                  transition: 'all 0.2s ease'
                }}
              >
                BTC
              </button>
              <button
                onClick={() => setPriceDisplay('USD')}
                style={{
                  padding: '8px 16px',
                  backgroundColor: priceDisplay === 'USD' ? '#FFC300' : 'transparent',
                  color: priceDisplay === 'USD' ? '#001D3D' : '#FFD60A',
                  border: 'none',
                  borderRadius: '6px',
                  cursor: 'pointer',
                  fontSize: '0.9em',
                  fontWeight: '500',
                  transition: 'all 0.2s ease'
                }}
              >
                USD
              </button>
            </div>
          </div>
        </div>

        <div style={{ overflowX: 'auto' }}>
          {isLoadingCollections ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
              color: '#FFD60A'
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px'
              }}>
                <div style={{
                  width: '40px',
                  height: '40px',
                  border: '3px solid #FFD60A',
                  borderTop: '3px solid transparent',
                  borderRadius: '50%',
                  animation: 'spin 1s linear infinite'
                }} />
                <span>Loading collections...</span>
              </div>
            </div>
          ) : collections.length === 0 ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
              color: '#FFD60A',
              backgroundColor: 'rgba(0, 41, 82, 0.3)',
              borderRadius: '10px',
              padding: '20px',
              textAlign: 'center',
              fontSize: '1.1em'
            }}>
              No results in this time frame, try another
            </div>
          ) : (
            <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 4px' }}>
              <thead>
                <tr style={{ textAlign: 'left' }}>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500', width: '40px' }}></th>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500', width: '40px' }}>#</th>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500' }}>Collection</th>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500', textAlign: 'right' }}>Floor</th>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500', textAlign: 'right' }}>Floor {timeFrame} %</th>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500', textAlign: 'right' }}>Volume</th>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500', textAlign: 'right' }}>Sales</th>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500', textAlign: 'right' }}>Pending</th>
                  <th style={{ padding: '12px 8px', color: '#666', fontWeight: '500', textAlign: 'right' }}>Listed</th>
                </tr>
              </thead>
              <tbody>
                {(activeTab === 'top' ? collections : collections.filter(c => watchlist.includes(c.collectionSymbol)))
                  .slice(0, showRows)
                  .map((collection, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: 'rgba(0, 41, 82, 0.3)',
                        transition: 'background-color 0.2s ease',
                      }}
                    >
                      <td style={{ padding: '16px 8px', color: '#666' }}>
                        <button
                          onClick={() => {
                            if (watchlist.includes(collection.collectionSymbol)) {
                              setWatchlist(watchlist.filter(symbol => symbol !== collection.collectionSymbol));
                            } else {
                              setWatchlist([...watchlist, collection.collectionSymbol]);
                            }
                          }}
                          style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '4px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {watchlist.includes(collection.collectionSymbol) ? (
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="#FFD60A" stroke="#FFD60A">
                              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                            </svg>
                          ) : (
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FFD60A" strokeWidth="2">
                              <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
                            </svg>
                          )}
                        </button>
                      </td>
                      <td style={{ padding: '16px 8px', color: '#666' }}>{index + 1}</td>
                      <td style={{ padding: '16px 8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                          <img
                            src={collection.image}
                            alt={collection.name}
                            style={{
                              width: '32px',
                              height: '32px',
                              borderRadius: '8px',
                              objectFit: 'cover'
                            }}
                          />
                          <Link
                            to={`/collections/${collection.collectionSymbol}`}
                            style={{
                              color: '#FFD60A',
                              textDecoration: 'none',
                              fontSize: '0.95em',
                              fontWeight: '500'
                            }}
                          >
                            {collection.name}
                          </Link>
                        </div>
                      </td>
                      <td style={{ padding: '16px 8px', textAlign: 'right', color: '#fff' }}>
                        {collection.fp ?
                          priceDisplay === 'BTC' ?
                            `${collection.fp.toFixed(3)} BTC` :
                            formatNumber(collection.fp * btcPrice, 'USD')
                          : '--'}
                      </td>
                      <td style={{
                        padding: '16px 8px',
                        textAlign: 'right',
                        color: collection.fpPctChg > 0 ? '#4CBB17' : '#FF6347'
                      }}>
                        {collection.fpPctChg ? `${collection.fpPctChg.toFixed(1) > 0 ? '+' : ''}${collection.fpPctChg.toFixed(1)}%` : '--'}
                      </td>
                      <td style={{ padding: '16px 8px', textAlign: 'right', color: '#fff' }}>
                        {collection.vol ?
                          priceDisplay === 'BTC' ?
                            `${collection.vol.toFixed(3)} BTC` :
                            formatNumber(collection.vol * btcPrice, 'USD')
                          : '--'}
                      </td>
                      <td style={{ padding: '16px 8px', textAlign: 'right', color: '#fff' }}>
                        {collection.txns || '--'}
                      </td>
                      <td style={{ padding: '16px 8px', textAlign: 'right', color: '#fff' }}>
                        {collection.pending || '0'}
                      </td>
                      <td style={{ padding: '16px 8px', textAlign: 'right', color: '#fff' }}>
                        {collection.listedCount ?
                          `${((collection.listedCount / collection.totalSupply) * 100).toFixed(1)}%` :
                          '--'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'left',
          gap: '10px',
          marginTop: '20px',
          alignItems: 'center'
        }}>
          {[10, 25, 50, 100].map((value) => (
            <button
              key={value}
              onClick={() => setShowRows(value)}
              style={{
                padding: '8px 16px',
                backgroundColor: showRows === value ? '#FFC300' : '#002952',
                color: showRows === value ? '#001D3D' : '#FFD60A',
                border: 'none',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '0.9em',
                fontWeight: '500',
                transition: 'all 0.2s ease'
              }}
            >
              {value}
            </button>
          ))}
        </div>

      </div>


    </div >
  );
};

export default Home;