
// Inscriptions.js
import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Inscriptions.css';

const Inscriptions = () => {
    const [inscriptionId, setInscriptionId] = useState('');
    const [inscriptionDetails, setInscriptionDetails] = useState(null);
    const [error, setError] = useState(null);

    const fetchInscriptionDetails = async (id) => {
        try {
            // Replace direct API URL with the proxy server endpoint
            const response = await axios.get(`http://185.208.172.5:4000/api/insdata?address=${id}`);

            console.log('API Response:', response.data);

            if (response.data?.data === 'no-data') {
                setError('No data found for the given inscription ID.');
                setInscriptionDetails(null);
                return;
            }

            if (response.data && response.data.data) {
                const data = response.data.data;

                setInscriptionDetails({
                    id: data.inscription_id || 'Unknown',
                    name: data.inscription_name || 'Unknown',
                    date: data.genesis_ts ? new Date(data.genesis_ts * 1000).toLocaleDateString() : 'Unknown',
                    wallet: data.wallet || 'No wallet available',
                    collectionName: data.collection_name || 'No collection name',
                    contentUrl: data.content_url || 'No content URL',
                    bisUrl: data.bis_url || 'No Best in Slot URL',
                    highResImage: data.metadata?.high_res_img_url || null,
                    attributes: data.metadata?.attributes || [],
                });
                setError(null);
            } else {
                setError('No data found for the given inscription ID.');
            }
        } catch (error) {
            console.error('Error fetching inscription data:', error);
            setError('Failed to fetch data. Please try again later.');
        }
    }


    const handleCheck = () => {
        if (inscriptionId.trim() !== '') {
            fetchInscriptionDetails(inscriptionId);
        } else {
            setError('Please enter a valid inscription ID.');
        }
    };

    const handleTryAnother = () => {
        setInscriptionId('');
        setInscriptionDetails(null);
        setError(null);
    };

    return (
        <div className="container">
            <h1>Inscription Checker</h1>
            {!inscriptionDetails ? (
                <div id="input-section">
                    <label htmlFor="inscription-id">Enter Inscription ID:</label>
                    <input
                        type="text"
                        id="inscription-id"
                        value={inscriptionId}
                        onChange={(e) => setInscriptionId(e.target.value)}
                        placeholder="Enter ID here..."
                    />
                    <button onClick={handleCheck}>CHECK</button>
                    {error && <p className="error-message">{error}</p>}
                </div>
            ) : (
                <div className="main-container">
                    
                    {/* Image Section */}
                    <div className="img-container">
                        <img src={inscriptionDetails.highResImage} alt={inscriptionDetails.name || "Inscription Image"} />
                        
                    </div>
                    
                    {/* Details Section */}
                    <div className="card">
                        <h3>Details</h3>
                        <p><strong>ID:</strong> {inscriptionDetails.id}</p>
                        <p><strong>Name:</strong> {inscriptionDetails.name}</p>
                        <p><strong>Date:</strong> {inscriptionDetails.date}</p>
                        <p><strong>Wallet:</strong> {inscriptionDetails.wallet}</p>
                        {inscriptionDetails.contentUrl && (
                            <p>
                                <strong>Content URL:</strong>
                                <a href={inscriptionDetails.contentUrl} target="_blank" rel="noopener noreferrer">
                                    View Content
                                </a>
                            </p>
                        )}
                        {inscriptionDetails.bisUrl && (
                            <p>
                                <strong>Best in Slot URL:</strong>
                                <a href={inscriptionDetails.bisUrl} target="_blank" rel="noopener noreferrer">
                                    View on Best in Slot
                                </a>
                            </p>
                        )}
                        <button onClick={handleTryAnother}>Try Another</button>
                    </div>

                    {/* Attributes Section */}
                    {inscriptionDetails.attributes?.length > 0 && (
                        <div className="card">
                            <h3>Attributes</h3>
                            <ul>
                                {inscriptionDetails.attributes.map((attribute, index) => (
                                    <li key={index}>
                                        <strong>{attribute.trait_type}:</strong> {attribute.value}
                                    </li>
                                ))}
                            </ul>
                            
                        </div>
                        
                    )}
                    
                </div>


            )}
        </div>
    );
};

export default Inscriptions;
