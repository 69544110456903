import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";


const CollectionDetails = () => {
  const [btcPrice, setBtcPrice] = useState(null);
  const [currency, setCurrency] = useState('BTC'); // Add state for currency toggle
  const { symbol } = useParams();
  const [collection, setCollection] = useState(null);
  const [stats, setStats] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('grid');
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const satsToBTC = (sats) => {
    if (!sats) return '-';
    return (sats / 1e8).toFixed(3);
  };

const getImageUrl = (token) => {
  if (token.contentURI && token.contentURI.startsWith('http')) {
    return token.contentURI; // Secondary choice
  }
  if (token.id) {
    return `https://ord-mirror.magiceden.dev/content/${token.id}`; // Ensure this is used
  }
  if (token.id) {
    return `https://ord-mirror-staging.magiceden.dev/content/${token.id}`; // Last fallback
  }
  if (token.id) {
    return `https://renderer.magiceden.dev/v2/render?id=${token.id}`; // Renderer fallback
  }
  if (token.contentPreviewURI && token.contentPreviewURI.startsWith('http')) {
    return token.contentPreviewURI; // Primary choice if available
  }
  return 'https://via.placeholder.com/300x300.png?text=Image+Not+Available'; // Placeholder
};

  
  

  const handleImageError = (e) => {
    const token = e.target.dataset.token ? JSON.parse(e.target.dataset.token) : null;
    const currentSrc = e.target.src;
  
    if (token) {
      if (currentSrc.includes('renderer.magiceden.dev')) {
        e.target.src = `https://ord-mirror.magiceden.dev/content/${token.id}`; // Try magiceden.dev next
      } else if (currentSrc.includes('ord-mirror.magiceden.dev')) {
        e.target.src = `https://ord-mirror-staging.magiceden.dev/content/${token.id}`; // Try staging
      } else {
        e.target.src = 'https://via.placeholder.com/300x300.png?text=Image+Not+Available'; // Final fallback
      }
    } else {
      e.target.src = 'https://via.placeholder.com/300x300.png?text=Image+Not+Available';
    }
  };
  

  const loadTokens = useCallback(async (pageNum) => {
    try {
      setLoadingMore(true);
      const headers = {
        accept: 'application/json',
        Authorization: `Bearer 40d02095-f917-4a2e-962f-cf2a69e50a65`,
      };

      const tokensRes = await axios.get(
        `http://185.208.172.5:4000/proxy/magiceden/v2/ord/btc/tokens`,
        {
          headers,
          params: {
            collectionSymbol: symbol,
            limit: 20,
            offset: (pageNum - 1) * 20,
          },
        }
      );

      if (tokensRes.data && Array.isArray(tokensRes.data.tokens)) {
        const newTokens = tokensRes.data.tokens;
        setTokens((prev) => (pageNum === 1 ? newTokens : [...prev, ...newTokens]));
        setHasMore(newTokens.length === 20);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to load tokens');
      setHasMore(false);
    } finally {
      setLoadingMore(false);
    }
  }, [symbol]);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);

      try {
        const headers = {
          accept: 'application/json',
          Authorization: `Bearer 40d02095-f917-4a2e-962f-cf2a69e50a65`,
        };

        const [collectionRes, statsRes] = await Promise.all([
          axios.get(
            `http://185.208.172.5:4000/proxy/magiceden/v2/ord/btc/collections/${symbol}`,
            { headers }
          ),
          axios.get(
            `http://185.208.172.5:4000/proxy/magiceden/v2/ord/btc/stat?collectionSymbol=${symbol}`,
            { headers }
          ),
        ]);

        const priceResponse = await axios.get('https://mempool.space/api/v1/prices');
        setBtcPrice(priceResponse.data.USD);

        setCollection(collectionRes.data);
        setStats(statsRes.data);
        await loadTokens(1);
      } catch (error) {
        setError(error.response?.data?.message || 'Failed to load collection data');
      } finally {
        setLoading(false);
      }
    };

    if (symbol) {
      fetchAllData();
    }
  }, [symbol, loadTokens]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.pageYOffset >=
        document.documentElement.offsetHeight - 1000 &&
        hasMore &&
        !loadingMore
      ) {
        setPage((prev) => prev + 1);
        loadTokens(page + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, loadingMore, page, loadTokens]);

  const formatPrice = (price) => {
    if (currency === 'BTC') {
      return `${satsToBTC(price)} BTC`;
    } else {
      return `$${(satsToBTC(price) * btcPrice).toFixed(0)} USD`;
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'linear-gradient(to bottom, #000814, #001D3D)', color: '#FFD60A' }}>
        <div style={{ textAlign: 'center' }}>
          <div style={{ width: '40px', height: '40px', border: '3px solid #FFD60A', borderTop: '3px solid transparent', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
          <p>Loading collection details...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'linear-gradient(to bottom, #000814, #001D3D)', color: '#FFD60A' }}>
        {error}
      </div>
    );
  }

  if (!collection || !stats) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'linear-gradient(to bottom, #000814, #001D3D)', color: '#FFD60A' }}>
        Collection not found
      </div>
    );
  }

  const sortedTokens = [...tokens].sort((a, b) => {
    if (a.listedPrice && b.listedPrice) {
      return a.listedPrice - b.listedPrice; // Sort listed tokens by ascending price
    }
    if (a.listedPrice) return -1; // Place listed items before unlisted
    if (b.listedPrice) return 1;  // Place unlisted items after listed
    return 0; // Maintain order for unlisted items
  });


  return (
    <div style={{ minHeight: '100vh', background: 'linear-gradient(to bottom, #000814, #001D3D)', padding: '32px' }}>
      {/* Header */}
      <div style={{ background: '#001D3D', borderRadius: '8px', border: '1px solid rgba(255, 214, 10, 0.1)', marginBottom: '32px', padding: '24px' }}>
        <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
          <img
            src={collection.imageURI}
            alt={collection.name}
            onError={handleImageError}
            style={{ width: '80px', height: '80px', borderRadius: '8px', objectFit: 'cover', background: '#002952' }}
          />
          <div>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold', color: '#FFD60A', marginBottom: '8px' }}>{collection.name}</h1>
            <a href={collection.twitterLink} target="_blank" rel="noopener noreferrer" style={{  color: '#FFD60A', fontSize: '1.5rem', margin:'0 5px'}}><FaXTwitter /></a>
            <a href={collection.discordLink} target="_blank" rel="noopener noreferrer" style={{  color: '#FFD60A', fontSize: '1.5rem', margin:'0 5px'}}><FaDiscord /></a>
            <p style={{ color: '#7a7a7a', marginBottom: '16px' }}>{collection.description}</p>
            <div style={{ display: 'flex', gap: '16px', fontSize: '14px' }}>
              <span style={{ color: '#7a7a7a' }}>Floor: {formatPrice(stats.floorPrice)}</span>
              <span style={{ color: '#7a7a7a' }}>•</span>
              <span style={{ color: '#7a7a7a' }}>Listed: {stats.totalListed}/{stats.supply}</span>
              <span style={{ color: '#7a7a7a' }}>•</span>
              <span style={{ color: '#7a7a7a' }}>Owners: {stats.owners}</span>
              <span style={{ color: '#7a7a7a' }}>•</span>
              <span style={{ color: '#7a7a7a' }}>Volume: {formatPrice(stats.totalVolume)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Action Bar */}
      <div style={{ background: '#001D3D', borderRadius: '8px', border: '1px solid rgba(255, 214, 10, 0.1)', marginBottom: '32px', padding: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', gap: '16px' }}>
            <button
              onClick={() => setViewMode('grid')}
              style={{
                padding: '8px 16px',
                borderRadius: '8px',
                fontWeight: '500',
                cursor: 'pointer',
                backgroundColor: viewMode === 'grid' ? '#FFC300' : 'transparent',
                color: viewMode === 'grid' ? '#001D3D' : '#FFD60A',
                border: '1px solid #FFD60A',
              }}
            >
              Grid View
            </button>
            <button
              onClick={() => setViewMode('list')}
              style={{
                padding: '8px 16px',
                borderRadius: '8px',
                fontWeight: '500',
                cursor: 'pointer',
                backgroundColor: viewMode === 'list' ? '#FFC300' : 'transparent',
                color: viewMode === 'list' ? '#001D3D' : '#FFD60A',
                border: '1px solid #FFD60A',
              }}
            >
              List View
            </button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>
              <button
                onClick={() => setCurrency('BTC')}
                style={{
                  padding: '8px 16px',
                  borderRadius: '8px',
                  fontWeight: '500',
                  cursor: 'pointer',
                  backgroundColor: currency === 'BTC' ? '#FFC300' : 'transparent',
                  color: currency === 'BTC' ? '#001D3D' : '#FFD60A',
                  border: '1px solid #FFD60A',
                }}
              >
                BTC
              </button>
              <button
                onClick={() => setCurrency('USD')}
                style={{
                  padding: '8px 16px',
                  borderRadius: '8px',
                  fontWeight: '500',
                  cursor: 'pointer',
                  backgroundColor: currency === 'USD' ? '#FFC300' : 'transparent',
                  color: currency === 'USD' ? '#001D3D' : '#FFD60A',
                  border: '1px solid #FFD60A',
                }}
              >
                USD
              </button>
            </div>

            <input
              type="search"
              placeholder="Search by inscription number..."
              style={{
                background: '#002952',
                border: '1px solid rgba(255, 214, 10, 0.1)',
                borderRadius: '8px',
                padding: '8px 16px',
                width: '240px',
                color: 'white',
                outline: 'none',
              }}
            />
          </div>
        </div>
      </div>

      {/* Content */}
      {viewMode === 'grid' ? (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))', gap: '16px' }}>
          {sortedTokens.map((token, index) => (
            <Link
              to={`/ordinals/item-details/${token.id}`}
              style={{ textDecoration: 'none' }} // Optional to remove underline
            >
              <div
                key={token.inscriptionNumber || index}
                style={{
                  background: '#002952',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  border: '1px solid rgba(255, 214, 10, 0.1)',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={getImageUrl(token)}
                  alt={token.meta?.name || `Item #${token.inscriptionNumber}`}
                  onError={handleImageError}
                  style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                />
                <div style={{ padding: '8px', borderTop: '1px solid rgba(255, 214, 10, 0.1)' }}>
                  <h3 style={{ fontSize: '14px', color: 'white', marginBottom: '4px' }}>
                    {token.meta?.name || `Inscription #${token.inscriptionNumber}`}
                  </h3>
                  <p style={{ fontSize: '12px', color: '#7a7a7a' }}>Price: {formatPrice(token.listedPrice)}</p>
                </div>
              </div>
            </Link>
          ))}


        </div>
      ) : (
        <div style={{ background: '#13111C', padding: '16px', borderRadius: '8px' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', padding: '8px', color: '#7a7a7a' }}>Item</th>
                <th style={{ textAlign: 'left', padding: '8px', color: '#7a7a7a' }}>Listing Price</th>
                <th style={{ textAlign: 'left', padding: '8px', color: '#7a7a7a' }}>Floor Difference</th>
                <th style={{ textAlign: 'left', padding: '8px', color: '#7a7a7a' }}>Owner</th>
                <th style={{ textAlign: 'right', padding: '8px', color: '#7a7a7a' }}>Listed Time</th>
              </tr>
            </thead>
            <tbody>
              {sortedTokens.map((token, index) => (
                <tr
                  key={token.inscriptionNumber || index}
                  style={{ borderBottom: '1px solid rgba(255, 214, 10, 0.1)', cursor: 'pointer' }}
                >
                  <td style={{ padding: '8px', color: 'white', display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Link
                      to={`/ordinals/item-details/${token.id}`}
                      style={{ display: 'flex', textDecoration: 'none', color: 'inherit' }} // Optional for consistent styling
                    >
                      <img
                        src={getImageUrl(token)}
                        alt={token.meta?.name || `Item #${token.inscriptionNumber}`}
                        onError={handleImageError}
                        style={{ width: '40px', height: '40px', borderRadius: '4px', objectFit: 'cover', background: '#002952' }}
                      />
                      <p style={{ margin: 'auto 10px' }}>{token.meta?.name || `Item #${token.inscriptionNumber}`}</p>
                    </Link>
                  </td>
                  <td style={{ padding: '8px', color: 'white' }}>{formatPrice(token.listedPrice)}</td>
                  <td style={{
                    padding: '8px',
                    color: token.listedPrice
                      ? (token.listedPrice / stats.floorPrice) > 1
                        ? 'green'
                        : (token.listedPrice / stats.floorPrice) < 1
                          ? 'red'
                          : 'white'
                      : 'white',
                  }}>
                    {token.listedPrice && stats.floorPrice
                      ? `${(((token.listedPrice / stats.floorPrice) - 1) * 100).toFixed(2)}%`
                      : '0.00%'}
                  </td>
                  <td style={{ padding: '8px', color: '#7a7a7a' }}>{token.owner?.slice(0, 6)}...{token.owner?.slice(-4)}</td>
                  <td style={{ textAlign: 'right', padding: '8px', color: '#7a7a7a' }}>--</td>
                </tr>
              ))}
            </tbody>


          </table>
        </div>
      )}

      {loadingMore && (
        <div style={{ textAlign: 'center', marginTop: '32px' }}>
          <div style={{ width: '32px', height: '32px', border: '2px solid #FFD60A', borderTop: '2px solid transparent', borderRadius: '50%', animation: 'spin 1s linear infinite' }}></div>
        </div>
      )}
    </div>
  );
};

export default CollectionDetails;
