// client/src/pages/Portfolio.js
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { AddressPurpose, BitcoinNetworkType, getAddress } from "sats-connect";
import { useAuth } from "../context/AuthContext";
import { Line } from "react-chartjs-2";
import { Pie } from "react-chartjs-2";
import {
  LayoutDashboard,
  Coins,
  Banknote,
  Image,
  Activity,
  User,
  Wallet,
  Plus,
  X,
} from "lucide-react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "chart.js/auto";

function Portfolio() {
  const { login, logout, user } = useAuth();
  const [, setStatus] = useState("Not connected");
  const [paymentAddress, setPaymentAddress] = useState("N/A");
  const [ordinalsAddress, setOrdinalsAddress] = useState("N/A");
  const [connected, setConnected] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [inscriptions, setInscriptions] = useState([]);
  const [filteredInscriptions, setFilteredInscriptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [btcPrice, setBtcPrice] = useState(null);
  const [manualAddress, setManualAddress] = useState("");
  const [useManualInput, setUseManualInput] = useState(false);
  const [walletActivity, setWalletActivity] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [selectedKind, setSelectedKind] = useState("");
  const [totalValue, setTotalValue] = useState(0);
  const [totalRunesValue, setTotalRunesValue] = useState(0); // State to hold the total runes value
  const [totalBRC20Value, setTotalBRC20Value] = useState(0); // State to hold the total BRC-20 value
  const totalCombinedValue = totalValue + totalRunesValue + totalBRC20Value;
  const [runesList, setRunesList] = useState([]); // State to store the list of Runes
  const [brc20List, setBRC20List] = useState([]); // State to store the list of BRC-20 tokens
  const [selectedValueType, setSelectedValueType] = useState("Total");
  const [loading, setLoading] = useState(false);
  const [selectedCollection, setSelectedCollection] =
    useState("All Collections");
  const [activeSection, setActiveSection] = useState("overview");
  const itemsPerPage = 40;
  const [hasMore, setHasMore] = useState(true);
  const [allWalletActivities, setAllWalletActivities] = useState([]);
  const [totalBuyValue, setTotalBuyValue] = useState(0);
  const [tradeData, setTradeData] = useState([]);
  const observerRef = useRef();
  const [connectedWallets, setConnectedWallets] = useState([]);
  const [activeWallet, setActiveWallet] = useState(null);
  const [showAddWallet, setShowAddWallet] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [socialLinks, setSocialLinks] = useState({
    discord: "",
    twitter: "",
  });
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    // Check if Xverse or Unisat wallets are available
    if (typeof window.xverse !== "undefined") {
      console.log("Xverse wallet detected.");
    }
    if (typeof window.unisat !== "undefined") {
      console.log("Unisat wallet detected.");
    }
  }, []);



  const fetchWalletDetails = useCallback(async (paymentAddress) => {
    try {
      const response = await axios.get(
        `http://185.208.172.5:4000/proxy/blockcypher/${paymentAddress}/balance`
      );
      const balance = parseFloat(response.data?.final_balance / 1e8);
      console.log("BTC Balance:", balance);

      const priceResponse = await axios.get(
        "http://185.208.172.5:4000/proxy/mempool/prices"
      );
      setBtcPrice(priceResponse.data.USD);

      const addressDataResponse = await axios.get(
        `http://185.208.172.5:4000/proxy/mempool/address/${paymentAddress}`
      );
      const totalReceived = parseFloat(
        addressDataResponse.data.chain_stats.funded_txo_sum / 1e8
      );
      const totalSent = parseFloat(
        addressDataResponse.data.chain_stats.spent_txo_sum / 1e8
      );

      const txsResponse = await axios.get(
        `http://185.208.172.5:4000/proxy/mempool/address/${paymentAddress}/txs`
      );
      const totalFees = txsResponse.data.reduce(
        (acc, tx) => acc + (tx.fee || 0),
        0
      );

      const newWalletData = {
        address: paymentAddress,
        balance: balance,
        BtcPrice: priceResponse.data.USD,
        balanceInUsd: balance * priceResponse.data.USD,
        totalReceived: totalReceived,
        totalReceivedInUsd: totalReceived * priceResponse.data.USD,
        totalSent: totalSent,
        totalSentInUsd: totalSent * priceResponse.data.USD,
        totalFees: totalFees / 1e8,
        totalFeesInUsd: (totalFees / 1e8) * priceResponse.data.USD,
      };

      console.log('Setting wallet data:', newWalletData);
      setWalletData(newWalletData);
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      setWalletData(null);  // Reset on error
    }
  }, [setBtcPrice, setWalletData]);




  const fetchInscriptions = useCallback(async (ordinalsAddress) => {
    try {
      console.log("Fetching inscriptions for address:", ordinalsAddress);
      let allTokens = [];
      let hasMore = true;
      let offset = 0;
      const limit = 40;

      while (hasMore) {
        const response = await axios.get(
          `http://185.208.172.5:4000/proxy/inscriptions/${ordinalsAddress}?offset=${offset}&limit=${limit}`
        );

        if (response.data.tokens && response.data.tokens.length > 0) {
          allTokens = [...allTokens, ...response.data.tokens];
          offset += limit;
          hasMore = response.data.tokens.length === limit;
        } else {
          hasMore = false;
        }
      }

      const tokensWithPrices = await Promise.all(
        allTokens.map(async (token) => {
          if (token.collection && token.collection.name) {
            try {
              const priceResponse = await axios.get(
                `http://185.208.172.5:4000/proxy/tokens/${token.collection.symbol}`
              );
              token.floorPrice = parseFloat(
                priceResponse.data?.floorPrice / 1e8 || 0
              );
            } catch (error) {
              console.error(
                `Error fetching floor price for ${token.collection.name}:`,
                error
              );
              token.floorPrice = 0;
            }
          } else {
            token.floorPrice = 0;
          }
          return token;
        })
      );

      setInscriptions(tokensWithPrices);
      setFilteredInscriptions(tokensWithPrices);

      const totalValue = tokensWithPrices.reduce(
        (acc, token) => acc + token.floorPrice,
        0
      );
      setTotalValue(totalValue);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching inscriptions:", error);
      setLoading(false);
    }
  }, [setInscriptions, setFilteredInscriptions, setTotalValue, setLoading]);



  const fetchRunesValue = useCallback(async (ordinalsAddress) => {
    try {
      const response = await axios.get(
        `http://185.208.172.5:4000/api/runes?address=${ordinalsAddress}`
      );

      if (response.data && response.data.data) {
        const runesData = response.data.data;
        const runesWithPrices = await Promise.all(
          runesData.map(async (rune) => {
            try {
              const marketResponse = await axios.get(
                `http://185.208.172.5:4000/proxy/${rune.rune_name}`
              );
              if (marketResponse.data && marketResponse.data.floorUnitPrice) {
                rune.pricePerUnit = parseFloat(
                  marketResponse.data.floorUnitPrice.formatted
                );
              } else {
                rune.pricePerUnit = NaN;
              }
            } catch (error) {
              console.error(
                `Error fetching market data for rune ${rune.rune_name}:`,
                error
              );
              rune.pricePerUnit = NaN;
            }
            return rune;
          })
        );

        const totalValue = runesWithPrices.reduce((acc, rune) => {
          const balance = parseFloat(rune.total_balance);
          const decimals = parseInt(rune.decimals, 10);
          let pricePerUnit = parseFloat(rune.pricePerUnit);

          if (!isNaN(balance) && !isNaN(decimals) && !isNaN(pricePerUnit)) {
            const actualBalance = balance / Math.pow(10, decimals);
            const valueInSats = actualBalance * pricePerUnit;
            const valueInBTC = valueInSats / 1e8;
            return acc + valueInBTC;
          }
          return acc;
        }, 0);

        setTotalRunesValue(totalValue);
        setRunesList(runesWithPrices);
      }
    } catch (error) {
      console.error("Error fetching runes data:", error);
    }
  }, [setTotalRunesValue, setRunesList]);



  const fetchBRC20Value = useCallback(async (ordinalsAddress) => {
    try {
      const response = await axios.get(
        `http://185.208.172.5:4000/api/brc20?address=${ordinalsAddress}`
      );

      if (response.data && response.data.data) {
        const brc20Data = response.data.data;
        const totalValue = brc20Data.reduce((acc, token) => {
          const balance = parseFloat(token.overall_balance);
          const pricePerUnit = parseFloat(token.min_listed_unit_price);
          return acc + balance * pricePerUnit;
        }, 0);

        setTotalBRC20Value(totalValue / 1e8);
        setBRC20List(brc20Data);
      }
    } catch (error) {
      console.error("Error fetching BRC-20 data:", error);
    }
  }, [setTotalBRC20Value, setBRC20List]);



  const calculateDailyTrades = useCallback((activities) => {
    const dailyTrades = {};

    activities.forEach((activity) => {
      if (
        activity.kind === "buying_broadcasted" ||
        activity.kind === "mint_broadcasted"
      ) {
        const date = new Date(activity.createdAt).toISOString().split("T")[0];
        const listedPrice = activity.listedPrice || 0; // Use listedPrice or 0 if undefined

        if (!dailyTrades[date]) {
          dailyTrades[date] = { trades: 0, totalListedPrice: 0 };
        }

        dailyTrades[date].trades += 1;
        dailyTrades[date].totalListedPrice += listedPrice;
      }
    });

    const tradeData = Object.entries(dailyTrades)
      .map(([date, { trades, totalListedPrice }]) => ({
        date: new Date(date).toLocaleDateString(),
        trades,
        totalListedPrice: totalListedPrice.toFixed(2), // Format totalListedPrice to 2 decimal places
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    return tradeData;
  }, []);


  // Create a new function to fetch all activities initially
  const fetchAllActivitiesData = useCallback(
    async (address) => {
      try {
        const kinds = ["buying_broadcasted", "mint_broadcasted"];
        const promises = kinds.map((k) => {
          const url = `http://185.208.172.5:4000/proxy/magiceden/v2/ord/btc/activities?ownerAddress=${encodeURIComponent(
            address
          )}&kind=${k}`;
          return axios
            .get(url, {
              headers: {
                Accept: "application/json",
                Authorization: "Bearer 40d02095-f917-4a2e-962f-cf2a69e50a65",
              },
            })
            .then((response) => response.data.activities || []);
        });

        const results = await Promise.all(promises);
        const allActivities = results
          .flat()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setAllWalletActivities(allActivities);
        const trades = calculateDailyTrades(allActivities);
        setTradeData(trades);
      } catch (error) {
        console.error("Error fetching all activities:", error);
      }
    },
    [calculateDailyTrades]
  );



  const checkExistingEmail = async (email) => {
    try {
      const checkResponse = await axios.get(
        `http://185.208.172.5:5000/api/auth/check-email/${email}`
      );
      return checkResponse.data.exists;
    } catch (error) {
      console.error("Error checking email:", error);
      return false;
    }
  };

  const fetchWalletData = useCallback(async (wallet) => {
    setLoading(true);
    try {
      await Promise.all([
        fetchWalletDetails(wallet.paymentAddress),
        fetchInscriptions(wallet.ordinalsAddress),
        fetchRunesValue(wallet.ordinalsAddress),
        fetchBRC20Value(wallet.ordinalsAddress),
        fetchAllActivitiesData(wallet.ordinalsAddress),
      ]);
    } catch (error) {
      console.error("Error fetching wallet data:", error);
    }
    setLoading(false);
  }, [
    fetchWalletDetails,
    fetchInscriptions,
    fetchRunesValue,
    fetchBRC20Value,
    fetchAllActivitiesData,
    setLoading
  ]);

  // Update the handleEmailLogin function
  const handleEmailLogin = async () => {
    try {
      const response = await login(loginData.email, loginData.password);
      setConnected(true);
      setUserEmail(loginData.email);  


      if (response.user?.socialLinks) {
        console.log("Setting social links from login:", response.user.socialLinks);
        setSocialLinks({
          discord: response.user.socialLinks.discord || '',
          twitter: response.user.socialLinks.twitter || ''
        });
      }

      // Fetch user data including social links right after login
      try {
        const userDataResponse = await axios.get(
          `http://185.208.172.5:5000/api/wallets/${loginData.email}`
        );
        console.log("User data after login:", userDataResponse.data); // Debug log

        // Set social links if they exist
        if (userDataResponse.data && userDataResponse.data.socialLinks) {
          setSocialLinks(userDataResponse.data.socialLinks);
        }
      } catch (error) {
        console.error("Error fetching user data after login:", error);
      }

      // Continue with wallet data fetching
      if (response.user?.wallets?.length > 0) {
        const firstWallet = response.user.wallets[0];
        setConnectedWallets(response.user.wallets);
        setActiveWallet(firstWallet);
        setPaymentAddress(firstWallet.paymentAddress);
        setOrdinalsAddress(firstWallet.ordinalsAddress);
        await fetchWalletData(firstWallet);
      }
    } catch (error) {
      alert(error.message);
    }
  };



  const isDuplicateWallet = (newWallet) => {
    return connectedWallets.some(
      (wallet) =>
        wallet.paymentAddress === newWallet.paymentAddress ||
        wallet.ordinalsAddress === newWallet.ordinalsAddress
    );
  };

  const addNewWallet = async (walletData) => {
    console.log('Adding new wallet:', walletData);
    console.log('Current connected wallets:', connectedWallets);

    if (isDuplicateWallet(walletData)) {
      alert("This wallet is already connected");
      return;
    }

    try {
      const response = await axios.post("http://185.208.172.5:5000/api/wallets", {
        email: userEmail,
        wallet: walletData,
      });

      console.log('Server response for adding wallet:', response.data);

      // Update local state with the new wallet
      setConnectedWallets(prev => [...prev, walletData]);
      setActiveWallet(walletData);
      setPaymentAddress(walletData.paymentAddress);
      setOrdinalsAddress(walletData.ordinalsAddress);

      // Fetch data for the new wallet
      await fetchWalletData(walletData);
    } catch (error) {
      console.error("Error adding wallet:", error);
      alert("Failed to add wallet. Please try again.");
    }
  };

  useEffect(() => {
    let mounted = true;

    const initializeWallet = async () => {
      console.log('Initializing wallet, user:', user);
      if (mounted && user && user.wallets?.length > 0 && !activeWallet) {
        const firstWallet = user.wallets[0];
        console.log('First wallet:', firstWallet);
        if (mounted) {
          setConnectedWallets(user.wallets);
          setActiveWallet(firstWallet);
          setPaymentAddress(firstWallet.paymentAddress);
          setOrdinalsAddress(firstWallet.ordinalsAddress);
          await fetchWalletData(firstWallet);
        }
      }
    };

    initializeWallet();

    return () => {
      mounted = false;
    };
  }, [user, activeWallet, fetchWalletData]);

  useEffect(() => {
    const loadSavedWallets = async () => {
      if (userEmail && !connectedWallets.length) {
        // Only load if no wallets are connected
        try {
          const response = await axios.get(
            `http://185.208.172.5:5000/api/wallets/${userEmail}`
          );
          setConnectedWallets(response.data);
          if (response.data.length > 0 && !activeWallet) {
            setActiveWallet(response.data[0]);
          }
        } catch (error) {
          console.error("Error loading wallets:", error);
        }
      }
    };

    loadSavedWallets();
  }, [userEmail, connectedWallets.length, activeWallet]);

  const switchWallet = async (wallet) => {
    setLoading(true);
    try {
      setActiveWallet(wallet);
      setOrdinalsAddress(wallet.ordinalsAddress);
      setPaymentAddress(wallet.paymentAddress);

      // Clear previous data
      setWalletData(null);
      setInscriptions([]);
      setFilteredInscriptions([]);
      setWalletActivity([]);
      setAllWalletActivities([]);

      // Fetch new data
      await fetchWalletData(wallet);
    } catch (error) {
      console.error("Error switching wallet:", error);
      alert("Failed to switch wallet. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const removeWallet = async (walletToRemove) => {
    try {
      // Remove from database using ordinals address instead of id
      await axios.delete(
        `http://185.208.172.5:5000/api/wallets/${userEmail}/${walletToRemove.ordinalsAddress}`
      );

      // Update local state
      setConnectedWallets((prev) =>
        prev.filter((w) => w.ordinalsAddress !== walletToRemove.ordinalsAddress)
      );

      if (activeWallet?.ordinalsAddress === walletToRemove.ordinalsAddress) {
        const remainingWallets = connectedWallets.filter(
          (w) => w.ordinalsAddress !== walletToRemove.ordinalsAddress
        );
        if (remainingWallets.length > 0) {
          switchWallet(remainingWallets[0]);
        } else {
          disconnectWallet();
        }
      }
    } catch (error) {
      console.error("Error removing wallet:", error);
    }
  };

  const updateSocialLinks = async () => {
    try {
      await axios.post(`http://185.208.172.5:5000/api/wallets/social`, {
        email: userEmail,
        socialLinks,
      });
      alert("Social links updated successfully");
    } catch (error) {
      alert("Failed to update social links");
    }
  };

  useEffect(() => {
    const loadUserData = async () => {
      if (userEmail) {
        console.log("Loading user data for:", userEmail);
        try {
          const response = await axios.get(
            `http://185.208.172.5:5000/api/wallets/${userEmail}`
          );
          console.log("Response data:", response.data);
          if (response.data.socialLinks) {
            console.log("Setting social links to:", response.data.socialLinks);
            setSocialLinks(response.data.socialLinks);
          }
        } catch (error) {
          console.error("Error loading user data:", error);
        }
      }
    };

    loadUserData();
  }, [userEmail]);

  const handleSetPassword = async () => {
    if (passwordData.password !== passwordData.confirmPassword) {
      alert("Passwords don't match");
      return;
    }

    if (passwordData.password.length < 6) {
      alert("Password must be at least 6 characters long");
      return;
    }

    try {
      const registrationData = {
        email: userEmail,
        password: passwordData.password,
        wallets: connectedWallets.map(wallet => ({
          type: wallet.type,
          name: wallet.name,
          paymentAddress: wallet.paymentAddress,
          ordinalsAddress: wallet.ordinalsAddress
        }))
      };

      console.log('Attempting to register with data:', registrationData);

      const response = await axios.post(
        "http://185.208.172.5:5000/api/auth/register",
        registrationData
      );

      console.log('Registration response:', response.data);

      if (response.data.token) {
        // Store the token
        localStorage.setItem('token', response.data.token);
        setShowSetPassword(false);
        alert("Account created successfully!");
      } else {
        throw new Error('No token received');
      }
    } catch (error) {
      console.error('Registration error:', {
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
        data: error.response?.data
      });
      alert(error.response?.data?.message || "Failed to create account. Please try again.");
    }
  };




  const connectXverse = async () => {
    // If user is already logged in, skip email checks
    if (!user) {
      if (!userEmail.trim()) {
        alert("Please enter your email address");
        return;
      }

      const emailExists = await checkExistingEmail(userEmail);
      if (emailExists) {
        alert("An account with this email already exists. Please login with email and password.");
        setShowEmailLogin(true);
        return;
      }
    }

    try {
      await getAddress({
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: "Connect to Xverse Wallet",
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: async (response) => {
          const paymentAddressItem = response.addresses.find(
            (address) => address.purpose === AddressPurpose.Payment
          );
          const ordinalsAddressItem = response.addresses.find(
            (address) => address.purpose === AddressPurpose.Ordinals
          );

          setPaymentAddress(paymentAddressItem?.address || "N/A");
          setOrdinalsAddress(ordinalsAddressItem?.address || "N/A");

          const newWallet = {
            id: Date.now(),
            type: "xverse",
            name: `Xverse ${connectedWallets.length + 1}`,
            paymentAddress: paymentAddressItem?.address,
            ordinalsAddress: ordinalsAddressItem?.address,
          };

          addNewWallet(newWallet);
          setConnected(true);
          setCurrentPage(1);
          fetchWalletData(newWallet);

          // Only show set password if user is not logged in
          if (!user) {
            setShowSetPassword(true);
          }
        },
        onCancel: () => alert("Request canceled"),
      });
    } catch (error) {
      alert("Failed to connect Xverse Wallet.");
    }
  };

  const connectUnisat = async () => {
    if (!user) {
      if (!userEmail.trim()) {
        alert("Please enter your email address");
        return;
      }

      const emailExists = await checkExistingEmail(userEmail);
      if (emailExists) {
        alert("An account with this email already exists. Please login with email and password.");
        setShowEmailLogin(true);
        return;
      }
    }

    if (typeof window.unisat !== "undefined") {
      try {
        await window.unisat.disconnect();
        const accounts = await window.unisat.requestAccounts();

        setPaymentAddress(accounts[0]);
        setOrdinalsAddress(accounts[0]);

        const newWallet = {
          id: Date.now(),
          type: "unisat",
          name: `Unisat ${connectedWallets.length + 1}`,
          paymentAddress: accounts[0],
          ordinalsAddress: accounts[0],
        };

        addNewWallet(newWallet);
        setConnected(true);
        setCurrentPage(1);
        fetchWalletData(newWallet);
        if (!user) {
          setShowSetPassword(true);
        }
      } catch (error) {
        console.error("Failed to connect Unisat Wallet:", error);
        alert("Failed to connect Unisat Wallet. Please try again.");
      }
    } else {
      alert("Please install Unisat Wallet extension.");
    }
  };

  const handleManualAddressSubmit = async () => {
    if (!user) {
      if (!userEmail.trim()) {
        alert("Please enter your email address");
        return;
      }

      const emailExists = await checkExistingEmail(userEmail);
      if (emailExists) {
        alert("An account with this email already exists. Please login with email and password.");
        setShowEmailLogin(true);
        return;
      }
    }
    if (manualAddress) {
      setPaymentAddress(manualAddress);
      setOrdinalsAddress(manualAddress);

      const newWallet = {
        id: Date.now(),
        type: "manual",
        name: `Wallet ${connectedWallets.length + 1}`,
        paymentAddress: manualAddress,
        ordinalsAddress: manualAddress,
      };

      addNewWallet(newWallet);
      setConnected(true);
      setCurrentPage(1);
      setManualAddress("");
      fetchWalletData(newWallet);
      if (!user) {
        setShowSetPassword(true);
      }
    } else {
      alert("Please enter a valid BTC address.");
    }
  };

  const disconnectWallet = () => {
    logout();
    setStatus("Not connected");
    setPaymentAddress("N/A");
    setOrdinalsAddress("N/A");
    setConnected(false);
    setWalletData(null);
    setInscriptions([]);
    setFilteredInscriptions([]);
    setManualAddress("");
    setCurrentPage(1);
    setTotalRunesValue(0);
    setTotalBRC20Value(0);
    setActiveSection("overview");
    setWalletActivity([]);
    setAllWalletActivities([]);
    setSelectedKind("all");
    setActivityLoading(false);
    setHasMore(true);
    setTotalBuyValue(0);
    setTradeData([]);
    setConnectedWallets([]);
    setActiveWallet(null);
  };




  // Add this new useEffect
  useEffect(() => {
    // Check if wallet is connected
    if (ordinalsAddress && ordinalsAddress !== "N/A") {
      // Fetch activities just for total buy calculation
      const fetchTotalBuy = async () => {
        try {
          const kinds = ["buying_broadcasted", "mint_broadcasted"]; // Only fetch the kinds we need
          const promises = kinds.map((k) => {
            const url = `http://185.208.172.5:4000/proxy/magiceden/v2/ord/btc/activities?ownerAddress=${encodeURIComponent(
              ordinalsAddress
            )}&kind=${k}`;
            return axios
              .get(url, {
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer 40d02095-f917-4a2e-962f-cf2a69e50a65",
                },
              })
              .then((response) => response.data.activities || []);
          });

          const results = await Promise.all(promises);
          const allActivities = results.flat();

          // Calculate total buy
          const totalBuy = allActivities.reduce((total, activity) => {
            if (activity.kind === "mint_broadcasted") {
              return total + (activity.listedPrice || 0);
            }
            if (
              activity.kind === "buying_broadcasted" &&
              activity.newOwner === ordinalsAddress
            ) {
              return total + (activity.listedPrice || 0);
            }
            return total;
          }, 0);

          setTotalBuyValue(totalBuy);
        } catch (error) {
          console.error("Error fetching total buy:", error);
        }
      };

      fetchTotalBuy();
    }
  }, [ordinalsAddress]);





  const fetchWalletActivity = useCallback(
    async (kind) => {
      console.log('Starting fetchWalletActivity with kind:', kind);
      try {
        setActivityLoading(true);
        setWalletActivity([]);
        setAllWalletActivities([]);
  
        if (!ordinalsAddress || ordinalsAddress === "N/A") {
          return;
        }
  
        // For single kind, keep the current implementation
        if (kind !== "all") {
          try {
            const response = await axios.get(
              `http://185.208.172.5:4000/proxy/magiceden/v2/ord/btc/activities`,
              {
                params: {
                  ownerAddress: ordinalsAddress,
                  kind: kind
                },
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer 40d02095-f917-4a2e-962f-cf2a69e50a65",
                }
              }
            );
            
            const activities = response.data.activities || [];
            const sortedActivities = activities.sort((a, b) => 
              new Date(b.createdAt) - new Date(a.createdAt)
            );
            
            setAllWalletActivities(sortedActivities);
            setWalletActivity(sortedActivities.slice(0, 15));
            setHasMore(sortedActivities.length > 15);
            return;
          } catch (error) {
            console.error(`Error fetching ${kind} activities:`, error);
            setAllWalletActivities([]);
            setWalletActivity([]);
            setHasMore(false);
            return;
          }
        }
  
        // For "all", use parallel requests with chunks
        const allKinds = [
          "buying_broadcasted", 
          "mint_broadcasted",
          "transfer", 
          "create", 
          "list", 
          "delist"
        ];
  
        // Split requests into chunks of 2 to avoid rate limits
        const chunkSize = 2;
        const chunks = [];
        for (let i = 0; i < allKinds.length; i += chunkSize) {
          chunks.push(allKinds.slice(i, i + chunkSize));
        }
  
        let allActivities = [];
  
        // Process chunks sequentially, but requests within chunks in parallel
        for (const chunk of chunks) {
          const chunkPromises = chunk.map(k => 
            axios.get(
              `http://185.208.172.5:4000/proxy/magiceden/v2/ord/btc/activities`,
              {
                params: {
                  ownerAddress: ordinalsAddress,
                  kind: k
                },
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer 40d02095-f917-4a2e-962f-cf2a69e50a65",
                }
              }
            ).then(response => response.data.activities || [])
             .catch(error => {
               console.error(`Error fetching ${k} activities:`, error);
               return [];
             })
          );
  
          const chunkResults = await Promise.all(chunkPromises);
          const newActivities = chunkResults.flat();
          allActivities = [...allActivities, ...newActivities];
  
          // Update UI after each chunk
          const sortedActivities = allActivities.sort((a, b) => 
            new Date(b.createdAt) - new Date(a.createdAt)
          );
          setAllWalletActivities(sortedActivities);
          setWalletActivity(sortedActivities.slice(0, 15));
          setHasMore(sortedActivities.length > 15);
  
          // Small delay between chunks to avoid rate limits
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
  
      } catch (error) {
        console.error("Error in fetchWalletActivity:", error);
        setAllWalletActivities([]);
        setWalletActivity([]);
        setHasMore(false);
      } finally {
        setActivityLoading(false);
      }
    },
    [ordinalsAddress]
  );


  const handleKindChange = async (event) => {
    const newKind = event.target.value;
    setSelectedKind(newKind);
    setWalletActivity([]);
    setAllWalletActivities([]);
    setHasMore(true);
    await fetchWalletActivity(newKind);
  };



  // Add this effect near your other useEffects
  useEffect(() => {
    if (
      activeSection === "activities" &&
      ordinalsAddress &&
      ordinalsAddress !== "N/A"
    ) {
      setSelectedKind("all");
      fetchWalletActivity("all");
    }
  }, [activeSection, ordinalsAddress, fetchWalletActivity]);

  useEffect(() => {
    const currentObserverRef = observerRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !activityLoading) {
          const currentLength = walletActivity.length;
          const nextItems = allWalletActivities.slice(
            currentLength,
            currentLength + 15
          );
          setWalletActivity((prev) => [...prev, ...nextItems]);
          setHasMore(currentLength + 15 < allWalletActivities.length);
        }
      },
      { threshold: 0.5 }
    );

    if (currentObserverRef) {
      observer.observe(currentObserverRef);
    }

    return () => {
      if (currentObserverRef) {
        observer.unobserve(currentObserverRef);
      }
    };
  }, [hasMore, activityLoading, walletActivity.length, allWalletActivities]);



  const getImageUrl = (inscription) => {
    // Try renderer URL first
    if (inscription.id) {
      return `https://renderer.magiceden.dev/v2/render?id=${inscription.id}`;
    }

    // Fallback to content preview
    if (
      inscription.contentPreviewURI &&
      inscription.contentPreviewURI.startsWith("http")
    ) {
      return inscription.contentPreviewURI;
    }

    // Try content URI
    if (inscription.contentURI && inscription.contentURI.startsWith("http")) {
      return inscription.contentURI;
    }

    // Try ord mirror
    if (inscription.id) {
      return `https://ord-mirror-staging.magiceden.dev/content/${inscription.id}`;
    }

    // Final fallback
    return "https://via.placeholder.com/300x300.png?text=Image+Not+Available";
  };

  const handleImageError = (e) => {
    const currentSrc = e.target.src;
    const token = e.target.dataset.token
      ? JSON.parse(e.target.dataset.token)
      : null;

    if (token) {
      if (currentSrc.includes("renderer.magiceden.dev")) {
        // Try ord mirror if renderer fails
        e.target.src = `https://ord-mirror-staging.magiceden.dev/content/${token.id}`;
      } else if (currentSrc.includes("ord-mirror-staging")) {
        // Try content preview if ord mirror fails
        e.target.src = token.contentPreviewURI || token.contentURI;
      } else {
        // If all else fails, show placeholder
        e.target.src =
          "https://via.placeholder.com/300x300.png?text=Image+Not+Available";
      }
    } else {
      e.target.src =
        "https://via.placeholder.com/300x300.png?text=Image+Not+Available";
    }
  };

  const getTopAssets = () => {
    const inscriptionsWithValue = inscriptions.reduce((acc, inscription) => {
      const existing = acc.find(
        (item) => item.name === inscription.collection?.name
      );
      if (existing) {
        existing.value += inscription.floorPrice || 0;
      } else {
        acc.push({
          type: "Inscription",
          name: inscription.collection?.name,
          value: inscription.floorPrice || 0,
        });
      }
      return acc;
    }, []);

    const runesWithValue = runesList.map((rune) => ({
      type: "Rune",
      name: rune.spaced_rune_name || rune.rune_name,
      value:
        (rune.pricePerUnit *
          (parseFloat(rune.total_balance) /
            Math.pow(10, parseInt(rune.decimals, 10)))) /
        1e8 || 0,
    }));

    const brc20WithValue = brc20List.map((token) => ({
      type: "BRC-20",
      name: token.ticker,
      value:
        (parseFloat(token.overall_balance) *
          parseFloat(token.min_listed_unit_price)) /
        1e8 || 0,
    }));

    const allAssets = [
      ...inscriptionsWithValue,
      ...runesWithValue,
      ...brc20WithValue,
    ];
    allAssets.sort((a, b) => b.value - a.value);

    return allAssets.slice(0, 5);
  };

  // Handle filtering inscriptions by collection
  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedCollection(selected);
    if (selected === "All Collections") {
      setFilteredInscriptions(inscriptions);
    } else if (selected === "Others") {
      setFilteredInscriptions(
        inscriptions.filter((inscription) => !inscription.collection?.name)
      );
    } else {
      setFilteredInscriptions(
        inscriptions.filter(
          (inscription) => inscription.collection?.name === selected
        )
      );
    }
    setCurrentPage(1);
  };






  // Pagination control
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredInscriptions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredInscriptions.length / itemsPerPage);

  const uniqueCollections = [
    "All Collections",
    ...[
      ...new Set(
        inscriptions
          .map((inscription) => inscription.collection?.name)
          .filter((name) => name)
      ),
    ].sort(),
    "Others",
  ];

  const navigationItems = [
    {
      id: "overview",
      label: "Overview",
      icon: LayoutDashboard,
      color: "#FFC300",
    },
    { id: "runes", label: "Runes List", icon: Coins, color: "#4CAF50" },
    { id: "brc20", label: "BRC-20 List", icon: Banknote, color: "#007BFF" },
    {
      id: "inscriptions",
      label: "Inscriptions",
      icon: Image,
      color: "#9C27B0",
    },
    { id: "activities", label: "Activities", icon: Activity, color: "#FF5722" },
  ];

  const truncateAddress = (address) => {
    if (!address || address === "N/A") return address;
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // For modern browsers
        await navigator.clipboard.writeText(text);
      } else {
        // Fallback for older browsers
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
        } catch (err) {
          console.error("Failed to copy text:", err);
        }
        textArea.remove();
      }
      // Optional: Add some visual feedback
      alert("Address copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const getTypeColor = (type) => {
    const colors = {
      send: "#1E3A8A", // Blue
      list: "#991B1B", // Red
      mint_broadcasted: "#065F46", // Green
      buying_broadcasted: "#065F46", // Green
      transfer: "#1E3A8A", // Blue
      delist: "#991B1B", // Red
      create: "#1E3A8A", // Blue
    };
    return colors[type.toLowerCase()] || "#1E3A8A";
  };

  const formatActivityType = (type) => {
    return type
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const formatTimeAgo = (date) => {
    const now = new Date();
    const activityDate = new Date(date);
    const diffDays = Math.floor((now - activityDate) / (1000 * 60 * 60 * 24));

    if (diffDays < 30) {
      return `${diffDays}d`;
    } else {
      const months = Math.floor(diffDays / 30);
      return `${months}mo`;
    }
  };

  useEffect(() => {
    const fetchBTCPrice = async () => {
      try {
        const priceResponse = await axios.get(
          "http://185.208.172.5:4000/proxy/mempool/prices"
        );
        setBtcPrice(priceResponse.data.USD);
      } catch (error) {
        console.error("Error fetching BTC price:", error);
      }
    };

    fetchBTCPrice();
    // You might want to add a refresh interval depending on your needs
    const interval = setInterval(fetchBTCPrice, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="portfolio"
      style={{ padding: "40px", textAlign: "center", color: "#FFD60A" }}
    >
      {loading && connected ? (
        <div>Loading...</div>
      ) : (
        <>
          {!connected ? (
            <div
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  backgroundColor: "#001D3D",
                  borderRadius: "20px",
                  padding: "30px",
                  width: "400px",
                  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
                }}
              >
                <h2
                  style={{
                    color: "#FFD60A",
                    textAlign: "center",
                    marginBottom: "30px",
                    fontSize: "24px",
                  }}
                >
                  Connect or Login
                </h2>

                {!useManualInput ? (
                  showEmailLogin ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      {/* Email Login Form */}
                      <input
                        type="email"
                        value={loginData.email}
                        onChange={(e) =>
                          setLoginData({ ...loginData, email: e.target.value })
                        }
                        placeholder="Email"
                        style={{
                          width: "100%",
                          padding: "15px",
                          marginBottom: "10px",
                          backgroundColor: "transparent",
                          border: "2px solid #FFD60A",
                          borderRadius: "25px",
                          color: "#FFD60A",
                          fontSize: "16px",
                          outline: "none",
                          textAlign: "center",
                        }}
                      />
                      <input
                        type="password"
                        value={loginData.password}
                        onChange={(e) =>
                          setLoginData({
                            ...loginData,
                            password: e.target.value,
                          })
                        }
                        placeholder="Password"
                        style={{
                          width: "100%",
                          padding: "15px",
                          marginBottom: "20px",
                          backgroundColor: "transparent",
                          border: "2px solid #FFD60A",
                          borderRadius: "25px",
                          color: "#FFD60A",
                          fontSize: "16px",
                          outline: "none",
                          textAlign: "center",
                        }}
                      />
                      <button
                        onClick={handleEmailLogin}
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "#4CAF50",
                          border: "2px solid #4CAF50",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        Login
                      </button>
                      <button
                        onClick={() => setShowEmailLogin(false)}
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "#FF6347",
                          border: "2px solid #FF6347",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        Back to Connect Options
                      </button>
                    </div>
                  ) : (

                    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                      <button
                        onClick={() => setShowEmailLogin(true)}
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "#007BFF",
                          border: "2px solid #007BFF",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        Login with Email
                      </button>

                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px 0",
                      }}>
                        <div style={{ flex: 1, height: "1px", backgroundColor: "#FFD60A", opacity: 0.2 }} />
                        <span style={{ margin: "0 15px", color: "#FFD60A", opacity: 0.6 }}>or connect wallet</span>
                        <div style={{ flex: 1, height: "1px", backgroundColor: "#FFD60A", opacity: 0.2 }} />
                      </div>
                      {/* Wallet Options */}

                      <input
                        type="email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        placeholder="Enter your email"
                        style={{
                          width: "100%",
                          padding: "15px",
                          paddingInline: "unset",
                          backgroundColor: "transparent",
                          border: "2px solid #FFD60A",
                          borderRadius: "25px",
                          color: "#FFD60A",
                          fontSize: "16px",
                          outline: "none",
                          textAlign: "center",
                        }}
                      />
                      <button
                        onClick={connectXverse}
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "#4CAF50",
                          border: "2px solid #4CAF50",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        Connect with Xverse
                      </button>
                      <button
                        onClick={connectUnisat}
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "#FFD60A",
                          border: "2px solid #FFD60A",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        Connect with Unisat
                      </button>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "10px 0",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            height: "1px",
                            backgroundColor: "#FFD60A",
                            opacity: 0.2,
                          }}
                        />
                        <span
                          style={{
                            margin: "0 15px",
                            color: "#FFD60A",
                            opacity: 0.6,
                          }}
                        >
                          or connect wallet
                        </span>
                        <div
                          style={{
                            flex: 1,
                            height: "1px",
                            backgroundColor: "#FFD60A",
                            opacity: 0.2,
                          }}
                        />
                      </div>
                      <button
                        onClick={() => setUseManualInput(true)}
                        style={{
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "#007BFF",
                          border: "2px solid #007BFF",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        Enter Address Manually
                      </button>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    {/* Manual Address Input */}
                    <input
                      type="email"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      placeholder="Enter your email"
                      style={{
                        width: "100%",
                        padding: "15px",
                        marginBottom: "10px",
                        paddingInline: "unset",
                        backgroundColor: "transparent",
                        border: "2px solid #FFD60A",
                        borderRadius: "25px",
                        color: "#FFD60A",
                        fontSize: "16px",
                        outline: "none",
                        textAlign: "center",
                      }}
                    />
                    <input
                      type="text"
                      value={manualAddress}
                      onChange={(e) => setManualAddress(e.target.value)}
                      placeholder="Enter BTC Address"
                      style={{
                        width: "100%",
                        padding: "15px",
                        margin: "0 0 10px",
                        paddingInline: "unset",
                        backgroundColor: "transparent",
                        border: "2px solid #FFD60A",
                        borderRadius: "25px",
                        color: "#FFD60A",
                        fontSize: "16px",
                        outline: "none",
                        textAlign: "center",
                      }}
                    />
                    <div style={{ display: "flex", gap: "10px" }}>
                      <button
                        onClick={handleManualAddressSubmit}
                        style={{
                          flex: 1,
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "#4CAF50",
                          border: "2px solid #4CAF50",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        Connect
                      </button>
                      <button
                        onClick={() => setUseManualInput(false)}
                        style={{
                          flex: 1,
                          padding: "15px",
                          backgroundColor: "transparent",
                          color: "#FF6347",
                          border: "2px solid #FF6347",
                          borderRadius: "25px",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", width: "100%" }}>
              {/* Main Content Area */}
              <div style={{ flex: 1, marginLeft: "20px" }}>
                {/* Overview Section */}
                {activeSection === "overview" && walletData && (
                  <>
                    <div
                      className="wallet-container"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        gap: "20px",
                        margin: "40px",
                      }}
                    >
                      <div
                        className="combined-wallet-chart"
                        style={{
                          backgroundColor: "#001D3D",
                          padding: "25px",
                          borderRadius: "15px",
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                          color: "#FFD60A",
                          display: "flex",
                          flexDirection: "column",
                          gap: "30px",
                          flex: "1 1 600px",
                        }}
                      >
                        {/* Asset Values Section */}
                        <div
                          className="wallet-value-box"
                          style={{
                            backgroundColor: "#001D3D",
                            padding: "25px",
                            borderRadius: "15px",
                            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                            color: "#FFD60A",
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                          }}
                        >
                          <h3
                            style={{
                              marginBottom: "25px",
                              fontWeight: "600",
                              fontSize: "1.8em",
                              textAlign: "left",
                            }}
                          >
                            Asset Values
                          </h3>

                          {/* Buttons Row */}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                              marginBottom: "30px",
                            }}
                          >
                            {[
                              { type: "Total", color: "#FFC300" },
                              { type: "Inscriptions", color: "#FFC300" },
                              { type: "Runes", color: "#FFC300" },
                              { type: "BRC-20", color: "#FFC300" },
                            ].map(({ type }) => (
                              <button
                                key={type}
                                onClick={() => setSelectedValueType(type)}
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor:
                                    selectedValueType === type
                                      ? "#FFC300"
                                      : "#002952",
                                  color:
                                    selectedValueType === type
                                      ? "#001D3D"
                                      : "#FFD60A",
                                  border: "none",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                  transition: "all 0.3s ease",
                                  fontWeight:
                                    selectedValueType === type ? "600" : "400",
                                  fontSize: "0.95em",
                                  flex: 1,
                                }}
                              >
                                {type}
                              </button>
                            ))}
                          </div>

                          {/* Value Display */}
                          <div
                            className="value-display"
                            style={{
                              backgroundColor: "#002952",
                              padding: "25px",
                              borderRadius: "12px",
                              color: "#FFD60A",
                              fontSize: "1.2em",
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            {selectedValueType === "Total" && (
                              <>
                                <div>Total Value:</div>
                                <div
                                  style={{
                                    fontSize: "1.4em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {parseFloat(totalCombinedValue).toFixed(4)}{" "}
                                  BTC
                                </div>
                                <div style={{ color: "#00FF7F" }}>
                                  $
                                  {(
                                    parseFloat(totalCombinedValue) *
                                    walletData.BtcPrice
                                  ).toFixed(0)}{" "}
                                  USD
                                </div>
                              </>
                            )}
                            {selectedValueType === "Inscriptions" && (
                              <>
                                <div>Total Inscriptions Value:</div>
                                <div
                                  style={{
                                    fontSize: "1.4em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {parseFloat(totalValue).toFixed(4)} BTC
                                </div>
                                <div style={{ color: "#00FF7F" }}>
                                  $
                                  {(
                                    parseFloat(totalValue) * walletData.BtcPrice
                                  ).toFixed(0)}{" "}
                                  USD
                                </div>
                              </>
                            )}
                            {selectedValueType === "Runes" && (
                              <>
                                <div>Total Runes Value:</div>
                                <div
                                  style={{
                                    fontSize: "1.4em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {parseFloat(totalRunesValue).toFixed(6)} BTC
                                </div>
                                <div style={{ color: "#00FF7F" }}>
                                  $
                                  {(
                                    parseFloat(totalRunesValue) *
                                    walletData.BtcPrice
                                  ).toFixed(0)}{" "}
                                  USD
                                </div>
                              </>
                            )}
                            {selectedValueType === "BRC-20" && (
                              <>
                                <div>Total BRC-20 Value:</div>
                                <div
                                  style={{
                                    fontSize: "1.4em",
                                    fontWeight: "500",
                                  }}
                                >
                                  {parseFloat(totalBRC20Value).toFixed(6)} BTC
                                </div>
                                <div style={{ color: "#00FF7F" }}>
                                  $
                                  {(
                                    parseFloat(totalBRC20Value) *
                                    walletData.BtcPrice
                                  ).toFixed(0)}{" "}
                                  USD
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {/* Line Chart Section */}
                        <div
                          className="wallet-line-chart"
                          style={{
                            backgroundColor: "#001D3D",
                            padding: "25px",
                            borderRadius: "15px",
                            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                            color: "#FFD60A",
                            flex: 2,
                            textAlign: "center",
                            height: "350px",
                          }}
                        >
                          <h3
                            style={{ marginBottom: "20px", fontWeight: "bold" }}
                          >
                            Value Changes Over Time
                          </h3>

                          <div style={{ height: "306px" }}>
                            <Line
                              data={{
                                labels: [
                                  "Day 1",
                                  "Day 2",
                                  "Day 3",
                                  "Day 4",
                                  "Day 5",
                                  "Day 6",
                                  "Day 7",
                                ],
                                datasets: [
                                  {
                                    label: selectedValueType,
                                    data:
                                      selectedValueType === "Inscriptions"
                                        ? [
                                          0.0118, 0.01125, 0.0119, 0.0121,
                                          0.0122, 0.01185, 0.0121,
                                        ]
                                        : selectedValueType === "Runes"
                                          ? [
                                            0.0039, 0.0041, 0.00405, 0.004,
                                            0.0035, 0.0041, 0.0043,
                                          ]
                                          : [
                                            0.0059, 0.0051, 0.00605, 0.006,
                                            0.0055, 0.0061, 0.0068,
                                          ],
                                    fill: false,
                                    borderColor:
                                      selectedValueType === "Inscriptions"
                                        ? "#FFC300"
                                        : selectedValueType === "Runes"
                                          ? "#4CAF50"
                                          : "#007BFF",
                                    tension: 0.3,
                                  },
                                ],
                              }}
                              options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                  legend: {
                                    labels: {
                                      color: "#FFD60A",
                                    },
                                  },
                                },
                                scales: {
                                  x: {
                                    ticks: {
                                      color: "#FFD60A",
                                    },
                                  },
                                  y: {
                                    ticks: {
                                      color: "#FFD60A",
                                    },
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Card 4: Portfolio Distribution */}
                      <div
                        className="wallet-chart-container"
                        style={{
                          backgroundColor: "#001D3D",
                          padding: "0 25px 70px",
                          borderRadius: "15px",
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                          color: "#FFD60A",
                          flex: "2 1 600px",
                          textAlign: "center",
                        }}
                      >
                        <h3
                          style={{ marginBottom: "20px", fontWeight: "bold" }}
                        >
                          Portfolio Distribution
                        </h3>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          {/* Pie Chart Section */}
                          <div
                            className="pie-chart-section"
                            style={{
                              flex: "1",
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                              height: "70%",
                            }}
                          >
                            <Pie
                              data={{
                                labels: ["Inscriptions", "Runes", "BRC-20"],
                                datasets: [
                                  {
                                    data: [
                                      totalValue,
                                      totalRunesValue,
                                      totalBRC20Value,
                                    ],
                                    backgroundColor: [
                                      "#FFC300",
                                      "#4CAF50",
                                      "#007BFF",
                                    ],
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                plugins: {
                                  legend: {
                                    position: "bottom",
                                    labels: {
                                      color: "#FFD60A",
                                    },
                                  },
                                },
                              }}
                            />
                          </div>
                          {/* Percentage Bars Section */}
                          <div
                            className="percentage-bars-section"
                            style={{ flex: "1", paddingLeft: "20px" }}
                          >
                            <div style={{ marginBottom: "10px" }}>
                              <span
                                style={{ color: "#FFC300", fontWeight: "bold" }}
                              >
                                Inscriptions:{" "}
                                {(
                                  (totalValue /
                                    (totalValue +
                                      totalRunesValue +
                                      totalBRC20Value)) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                              <div
                                style={{
                                  backgroundColor: "#002952",
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                  height: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    width: `${(
                                      (totalValue /
                                        (totalValue +
                                          totalRunesValue +
                                          totalBRC20Value)) *
                                      100
                                    ).toFixed(2)}%`,
                                    backgroundColor: "#FFC300",
                                    height: "100%",
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                              <span
                                style={{ color: "#4CAF50", fontWeight: "bold" }}
                              >
                                Runes:{" "}
                                {(
                                  (totalRunesValue /
                                    (totalValue +
                                      totalRunesValue +
                                      totalBRC20Value)) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                              <div
                                style={{
                                  backgroundColor: "#002952",
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                  height: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    width: `${(
                                      (totalRunesValue /
                                        (totalValue +
                                          totalRunesValue +
                                          totalBRC20Value)) *
                                      100
                                    ).toFixed(2)}%`,
                                    backgroundColor: "#4CAF50",
                                    height: "100%",
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                              <span
                                style={{ color: "#007BFF", fontWeight: "bold" }}
                              >
                                BRC-20:{" "}
                                {(
                                  (totalBRC20Value /
                                    (totalValue +
                                      totalRunesValue +
                                      totalBRC20Value)) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                              <div
                                style={{
                                  backgroundColor: "#002952",
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                  height: "10px",
                                  marginTop: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    width: `${(
                                      (totalBRC20Value /
                                        (totalValue +
                                          totalRunesValue +
                                          totalBRC20Value)) *
                                      100
                                    ).toFixed(2)}%`,
                                    backgroundColor: "#007BFF",
                                    height: "100%",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        gap: "20px",
                        margin: "40px",
                      }}
                    >
                      <div
                        className="wallet-box"
                        style={{
                          backgroundColor: "#001D3D",
                          padding: "25px",
                          borderRadius: "15px",
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                          color: "#FFD700",
                          flex: "1 1 600px",
                          maxWidth: "600px",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "1.8em",
                            fontWeight: "600",
                            marginBottom: "25px",
                            textAlign: "left",
                          }}
                        >
                          BTC Wallet Overview
                        </h3>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          {/* BTC Balance */}
                          <div
                            style={{
                              backgroundColor: "rgba(0, 29, 61, 0.5)",
                              padding: "15px",
                              borderRadius: "12px",
                              borderLeft: "4px solid #FFD700",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "0.9em",
                                color: "#999",
                                marginBottom: "8px",
                              }}
                            >
                              BTC Balance
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ fontSize: "1.2em" }}>
                                {walletData.balance.toFixed(3)} BTC
                              </span>
                              <span
                                style={{ color: "#00FF7F", fontSize: "1.1em" }}
                              >
                                ${walletData.balanceInUsd.toFixed(0)}
                              </span>
                            </div>
                          </div>

                          {/* Total Buy */}
                          <div
                            style={{
                              backgroundColor: "rgba(0, 29, 61, 0.5)",
                              padding: "15px",
                              borderRadius: "12px",
                              borderLeft: "4px solid #4CAF50",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "0.9em",
                                color: "#999",
                                marginBottom: "8px",
                              }}
                            >
                              Total Buy & Mint
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ fontSize: "1.2em" }}>
                                {(totalBuyValue / 1e8).toFixed(3)} BTC
                              </span>
                              <span
                                style={{ color: "#00FF7F", fontSize: "1.1em" }}
                              >
                                ${((totalBuyValue / 1e8) * btcPrice).toFixed(0)}
                              </span>
                            </div>
                          </div>

                          {/* Transactions Summary */}
                          <div
                            style={{
                              backgroundColor: "rgba(0, 29, 61, 0.5)",
                              padding: "15px",
                              borderRadius: "12px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "12px",
                            }}
                          >
                            {/* Total Received */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ color: "#999" }}>
                                Total Received
                              </span>
                              <div>
                                <span>
                                  {walletData.totalReceived.toFixed(3)} BTC
                                </span>
                                <span
                                  style={{
                                    color: "#00FF7F",
                                    marginLeft: "8px",
                                  }}
                                >
                                  ${walletData.totalReceivedInUsd.toFixed(0)}
                                </span>
                              </div>
                            </div>

                            {/* Total Sent */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ color: "#999" }}>Total Sent</span>
                              <div>
                                <span>
                                  {walletData.totalSent.toFixed(3)} BTC
                                </span>
                                <span
                                  style={{
                                    color: "#FF6347",
                                    marginLeft: "8px",
                                  }}
                                >
                                  ${walletData.totalSentInUsd.toFixed(0)}
                                </span>
                              </div>
                            </div>

                            {/* Total Fees */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ color: "#999" }}>Total Fees</span>
                              <div>
                                <span>
                                  {walletData.totalFees.toFixed(3)} BTC
                                </span>
                                <span
                                  style={{
                                    color: "#FF6347",
                                    marginLeft: "8px",
                                  }}
                                >
                                  ${walletData.totalFeesInUsd.toFixed(0)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          backgroundColor: "#001D3D",
                          padding: "25px",
                          borderRadius: "15px",
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                          border: "1px solid rgba(255, 211, 10, 0.1)",
                          flex: "1 1 600px",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "1.8em",
                            fontWeight: "600",
                            marginBottom: "25px",
                            textAlign: "left",
                            color: "#FFD60A",
                          }}
                        >
                          Trading Activity
                        </h3>

                        {tradeData.length > 0 ? (
                          <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                              data={tradeData.map((item) => ({
                                ...item,
                                totalListedPrice: item.totalListedPrice / 1e8, // Convert sats to BTC
                              }))}
                            >
                              <CartesianGrid
                                strokeDasharray="3 3"
                                stroke="rgba(255, 211, 10, 0.05)"
                                vertical={false}
                              />
                              <XAxis
                                dataKey="date"
                                stroke="#FFD60A"
                                tick={{ fontSize: 12, fill: "#FFD60A" }}
                                axisLine={{ stroke: "rgba(255, 211, 10, 0.2)" }}
                              />
                              <YAxis
                                domain={["auto", "auto"]} // Auto-adjust to totalListedPrice range
                                tickFormatter={(value) => `${value.toFixed(3)}`} // Format ticks in BTC
                                stroke="#FFD60A"
                                tick={{ fontSize: 12, fill: "#FFD60A" }}
                                axisLine={{ stroke: "rgba(255, 211, 10, 0.2)" }}
                                tickLine={{ stroke: "rgba(255, 211, 10, 0.2)" }}
                              />
                              <Tooltip
                                contentStyle={{
                                  backgroundColor: "rgba(0, 29, 61, 0.9)",
                                  border: "1px solid rgba(255, 211, 10, 0.2)",
                                  borderRadius: "8px",
                                  padding: "12px",
                                }}
                                labelStyle={{ color: "#FFD60A" }}
                                itemStyle={{ color: "#FFD60A" }}
                                formatter={(value, name) =>
                                  name === "totalListedPrice"
                                    ? `${value.toFixed(3)} BTC` // Display in BTC
                                    : value
                                }
                              />
                              {/* Bar for Total Listed Price */}
                              <Bar
                                dataKey="totalListedPrice"
                                name="Amount"
                                fill="#4CAF50"
                                radius={[4, 4, 0, 0]}
                              />
                              {/* Bar for Trades */}
                            </BarChart>
                          </ResponsiveContainer>
                        ) : (
                          <div
                            style={{
                              height: 300,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFD60A",
                            }}
                          >
                            No trading activity to display
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="loss-ben-top"
                      style={{ display: "flex", gap: "20px", margin: "40px" }}
                    >
                      <div
                        className="top-assets"
                        style={{
                          backgroundColor: "#001D3D",
                          padding: "25px",
                          borderRadius: "15px",
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                          color: "#FFD60A",
                          flex: "1",
                          textAlign: "center",
                        }}
                      >
                        <h3
                          style={{ marginBottom: "20px", fontWeight: "bold" }}
                        >
                          Top 5 Assets
                        </h3>
                        <div
                          style={{
                            maxHeight: "400px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {getTopAssets().length > 0 ? (
                            getTopAssets().map((asset, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: "15px",
                                  marginBottom: "10px",
                                  backgroundColor: "#002952",
                                  borderRadius: "10px",
                                  width: "100%",
                                  textAlign: "left",
                                }}
                              >
                                <p>
                                  <strong>Type:</strong> {asset.type}
                                </p>
                                <p>
                                  <strong>Name:</strong> {asset.name}
                                </p>
                                <p>
                                  <strong>Value:</strong>{" "}
                                  {asset.value.toFixed(4)} BTC
                                </p>
                              </div>
                            ))
                          ) : (
                            <p>No assets available</p>
                          )}
                        </div>
                      </div>
                      <div
                        className="loss-ben"
                        style={{
                          backgroundColor: "#001D3D",
                          padding: "25px",
                          borderRadius: "15px",
                          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                          color: "#FFD60A",
                          flex: "1",
                          textAlign: "center",
                        }}
                      >
                        <h3
                          style={{ marginBottom: "20px", fontWeight: "bold" }}
                        >
                          Daily Loss & Daily Benefits
                        </h3>
                        <div
                          style={{
                            maxHeight: "400px",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          <div
                            style={{
                              padding: "35px 15px",
                              marginBottom: "10px",
                              backgroundColor: "#002952",
                              borderRadius: "10px",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              <strong>Loss</strong>
                            </p>
                            <p>
                              <strong>Amount:</strong> BTC
                            </p>
                            <p>
                              <strong>Percentage:</strong>{" "}
                            </p>
                          </div>
                          <div
                            style={{
                              padding: "35px 15px",
                              marginBottom: "10px",
                              backgroundColor: "#002952",
                              borderRadius: "10px",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              <strong>Win</strong>
                            </p>
                            <p>
                              <strong>Amount:</strong> BTC
                            </p>
                            <p>
                              <strong>Percentage:</strong>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Runes Section */}
                {activeSection === "runes" && (
                  <div
                    className="runes-list-box"
                    style={{
                      backgroundColor: "#001D3D",
                      padding: "25px",
                      margin: "40px",
                      borderRadius: "15px",
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                      color: "#FFD60A",
                      flex: "1",
                      textAlign: "center",
                    }}
                  >
                    <h3 style={{ marginBottom: "20px", fontWeight: "bold" }}>
                      Runes Assets
                    </h3>
                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      {runesList.length > 0 ? (
                        runesList.map((rune, index) => (
                          <div
                            key={index}
                            style={{
                              padding: "15px",
                              marginBottom: "10px",
                              backgroundColor: "#002952",
                              borderRadius: "10px",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              <strong>Name:</strong>{" "}
                              {rune.spaced_rune_name || rune.rune_name}
                            </p>
                            <p>
                              <strong>Total Balance:</strong>{" "}
                              {rune.total_balance}
                            </p>
                            <p>
                              <strong>Price:</strong>{" "}
                              {Number.isInteger(rune.pricePerUnit)
                                ? rune.pricePerUnit
                                : rune.pricePerUnit.toFixed(6)}{" "}
                              sats
                            </p>
                          </div>
                        ))
                      ) : (
                        <p>No Runes Available</p>
                      )}
                    </div>
                  </div>
                )}

                {/* BRC-20 Section */}
                {activeSection === "brc20" && (
                  <div
                    className="brc20-list-box"
                    style={{
                      backgroundColor: "#001D3D",
                      padding: "25px",
                      margin: "40px",
                      borderRadius: "15px",
                      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                      color: "#FFD60A",
                      flex: "1",
                      textAlign: "center",
                    }}
                  >
                    <h3 style={{ marginBottom: "20px", fontWeight: "bold" }}>
                      BRC-20 Assets
                    </h3>
                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      {brc20List.length > 0 ? (
                        brc20List.map((token, index) => (
                          <div
                            key={index}
                            style={{
                              padding: "15px",
                              marginBottom: "10px",
                              backgroundColor: "#002952",
                              borderRadius: "10px",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              <strong>Ticker:</strong> {token.ticker}
                            </p>
                            <p>
                              <strong>Overall Balance:</strong>{" "}
                              {token.overall_balance}
                            </p>
                            <p>
                              <strong>Price:</strong>{" "}
                              {token.min_listed_unit_price} sats
                            </p>
                            {token.image_url && (
                              <img
                                src={token.image_url}
                                alt={`${token.ticker} icon`}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  marginTop: "10px",
                                }}
                              />
                            )}
                          </div>
                        ))
                      ) : (
                        <p>No BRC-20 Tokens Available</p>
                      )}
                    </div>
                  </div>
                )}

                {/* Inscriptions Section */}
                {activeSection === "inscriptions" && (
                  <div
                    className="inscription-gallery"
                    style={{ margin: "40px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#001D3D",
                        padding: "10px",
                        borderRadius: "10px",
                        color: "#FFD60A",
                      }}
                    >
                      <h3>Total Inscriptions: {inscriptions.length}</h3>
                      <div
                        className="filter-section"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <select
                          id="collectionFilter"
                          value={selectedCollection}
                          onChange={handleFilterChange}
                          style={{
                            padding: "10px",
                            borderRadius: "10px",
                            border: "none",
                            backgroundColor: "#002952",
                            color: "#FFD60A",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                            width: "300px",
                            fontSize: "1em",
                          }}
                        >
                          {uniqueCollections.map((collection, index) => (
                            <option key={index} value={collection}>
                              {collection}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {currentItems.length > 0 && (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(10, 1fr)",
                          gap: "30px",
                          marginTop: "20px",
                        }}
                      >
                        {currentItems.map((inscription, index) => (
                          <div
                            key={index}
                            className="inscription-box"
                            style={{
                              backgroundColor: "#001D3D",
                              padding: "20px",
                              borderRadius: "10px",
                            }}
                          >
                            <img
                              src={getImageUrl(inscription)}
                              alt={
                                inscription.meta?.name ||
                                `Item #${inscription.inscriptionNumber}`
                              }
                              data-token={JSON.stringify(inscription)}
                              onError={handleImageError}
                              style={{ width: "100%", borderRadius: "10px" }}
                            />
                            <p style={{ color: "#FFD60A", marginTop: "10px" }}>
                              {inscription.displayName}
                            </p>
                            <p style={{ color: "#FFD60A" }}>
                              Inscription Number: #
                              {inscription.inscriptionNumber}
                            </p>
                            {inscription.collection?.name && (
                              <>
                                <p style={{ color: "#FFD60A" }}>
                                  {inscription.collection?.symbol ? (
                          <Link
                          to={`/collections/${inscription.collection?.symbol}`}
                          style={{
                            color: '#FFD60A',
                            textDecoration: 'none',
                            fontSize: '0.95em',
                            fontWeight: '500'
                          }}
                        >
                        
                                      {inscription.collection?.name || "N/A"}
                                    </Link>
                                  ) : (
                                    inscription.collection?.name || "N/A"
                                  )}
                                </p>
                                <p style={{ color: "#FFD60A" }}>
                                  FP: {inscription.floorPrice} BTC
                                </p>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    {filteredInscriptions.length > itemsPerPage && (
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          disabled={currentPage === 1}
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#FFC300",
                            color: "#000814",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            marginRight: "10px",
                          }}
                        >
                          Previous
                        </button>
                        <span>
                          Page {currentPage} of {totalPages}
                        </span>
                        <button
                          onClick={() =>
                            setCurrentPage((prev) =>
                              Math.min(prev + 1, totalPages)
                            )
                          }
                          disabled={currentPage === totalPages}
                          style={{
                            padding: "10px 20px",
                            backgroundColor: "#4CAF50",
                            color: "#000814",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                )}

                {/* Activities Section */}
                {activeSection === "activities" && (
                  <div style={{ margin: "40px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#001D3D",
                        padding: "10px",
                        borderRadius: "10px",
                        color: "#FFD60A",
                      }}
                    >
                      <h2>Wallet Activity </h2>
                      <div
                        className="filter-section"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <select
                          id="activity-filter"
                          value={selectedKind}
                          onChange={handleKindChange}
                          style={{
                            padding: "10px",
                            borderRadius: "10px",
                            border: "none",
                            backgroundColor: "#002952",
                            color: "#FFD60A",
                            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                            width: "300px",
                            fontSize: "1em",
                          }}
                        >
                          <option value="all">All Activities</option>
                          <option value="transfer">Transfer</option>
                          <option value="create">Create</option>
                          <option value="list">List</option>
                          <option value="delist">Delist</option>
                          <option value="buying_broadcasted">
                            Buying Broadcasted
                          </option>
                          <option value="mint_broadcasted">
                            Mint Broadcasted
                          </option>
                        </select>
                      </div>
                    </div>

                    {activityLoading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          color: "#FFD60A",
                        }}
                      >
                        Loading...
                      </div>
                    ) : walletActivity.length > 0 ? (
                      <div
                        style={{
                          marginTop: "20px",
                          backgroundColor: "#001D3D",
                          borderRadius: "10px",
                          overflowX: "auto",
                        }}
                      >
                        <table
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <thead
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#001D3D",
                              zIndex: 1,
                            }}
                          >
                            <tr style={{ textAlign: "center", color: "#666" }}>
                              <th style={{ padding: "16px" }}>Name</th>
                              <th style={{ padding: "16px" }}>Type</th>
                              <th style={{ padding: "16px" }}>Seller</th>
                              <th style={{ padding: "16px" }}>Buyer</th>
                              <th style={{ padding: "16px" }}>Price</th>
                              <th style={{ padding: "16px" }}>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {walletActivity.map((activity, index) => (
                              <tr
                                key={index}
                                ref={
                                  index === walletActivity.length - 1
                                    ? observerRef
                                    : null
                                }
                                style={{
                                  borderBottom: "1px solid #2A2A2A",
                                }}
                              >
                                <td style={{ padding: "16px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "12px",
                                    }}
                                  >
                                    <img
                                      src={
                                        activity.collection?.imageURI ||
                                        "/api/placeholder/48/48"
                                      }
                                      alt=""
                                      style={{
                                        width: "48px",
                                        height: "48px",
                                        borderRadius: "8px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <div>
                                      <div
                                        style={{
                                          color: "#fff",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {activity.tokenId}
                                      </div>
                                      <div
                                        style={{
                                          color: "#666",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        {activity.collection?.name}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ padding: "16px" }}>
                                  <span
                                    style={{
                                      padding: "6px 12px",
                                      borderRadius: "6px",
                                      fontSize: "0.875rem",
                                      backgroundColor: getTypeColor(
                                        activity.kind
                                      ),
                                      color: "#fff",
                                    }}
                                  >
                                    {formatActivityType(activity.kind)}
                                  </span>
                                </td>
                                <td style={{ padding: "16px", color: "#666" }}>
                                  {activity.oldOwner === ordinalsAddress ? (
                                    <span
                                      style={{
                                        padding: "6px 12px",
                                        borderRadius: "6px",
                                        backgroundColor: "#2A2A2A",
                                        color: "#fff",
                                        fontSize: "0.875rem",
                                      }}
                                    >
                                      You
                                    </span>
                                  ) : (
                                    truncateAddress(activity.oldOwner) || "--"
                                  )}
                                </td>
                                <td style={{ padding: "16px", color: "#666" }}>
                                  {activity.newOwner === ordinalsAddress ? (
                                    <span
                                      style={{
                                        padding: "6px 12px",
                                        borderRadius: "6px",
                                        backgroundColor: "#2A2A2A",
                                        color: "#fff",
                                        fontSize: "0.875rem",
                                      }}
                                    >
                                      You
                                    </span>
                                  ) : (
                                    truncateAddress(activity.newOwner) || "--"
                                  )}
                                </td>
                                <td style={{ padding: "16px" }}>
                                  {activity.listedPrice ? (
                                    <div>
                                      <div style={{ color: "#fff" }}>
                                        {parseFloat(
                                          activity.listedPrice / 1e8
                                        ).toFixed(5)}{" "}
                                        BTC
                                      </div>
                                      <div
                                        style={{
                                          color: "#666",
                                          fontSize: "0.875rem",
                                        }}
                                      >
                                        $
                                        {(
                                          (activity.listedPrice / 1e8) *
                                          btcPrice
                                        ).toFixed(2)}
                                      </div>
                                    </div>
                                  ) : (
                                    "--"
                                  )}
                                </td>
                                <td style={{ padding: "16px", color: "#666" }}>
                                  {formatTimeAgo(activity.createdAt)}
                                </td>
                              </tr>
                            ))}
                            {activityLoading && (
                              <tr>
                                <td
                                  colSpan="6"
                                  style={{
                                    textAlign: "center",
                                    padding: "20px",
                                    color: "#FFD60A",
                                  }}
                                >
                                  Loading more...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {!hasMore && (
                          <div
                            style={{
                              textAlign: "center",
                              padding: "20px",
                              color: "#666",
                            }}
                          >
                            No more activities to load
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          color: "#FFD60A",
                        }}
                      >
                        No activities found for the selected filter.
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Navigation Bar with Profile and Wallet Management */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  position: "fixed",
                  left: "25px",
                  top: "30%",
                  transform: "translateY(-50%)",
                  zIndex: 10,
                }}
              >
                {/* Profile Button and Dropdown */}
                <div
                  className="profile-container"
                  style={{ position: "relative" }}
                >
                  <button
                    style={{
                      padding: "15px",
                      backgroundColor: "#001D3D",
                      color: "#FFD60A",
                      border: "none",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.transform = "translateX(10px)";
                      e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.2)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.transform = "translateX(0)";
                      e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
                    }}
                  >
                    <User size={28} />
                  </button>

                  <div
                    className="profile-dropdown"
                    style={{
                      position: "absolute",
                      left: "70px",
                      top: "80%",
                      transform: "translateY(-10%)",
                      backgroundColor: "#001D3D",
                      padding: "20px",
                      borderRadius: "15px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
                      color: "#FFD60A",
                      minWidth: "280px",
                      visibility: "hidden",
                      opacity: 0,
                      transition: "all 0.2s ease",
                      zIndex: 1000,
                    }}
                  >
                    <div style={{ marginBottom: "20px" }}>
                      <h3 style={{ color: "#FFD60A", marginBottom: "15px" }}>
                        Social Links
                      </h3>
                      <div style={{ marginBottom: "10px" }}>
                        <input
                          type="text"
                          value={socialLinks.discord || ''}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              discord: e.target.value,
                            }))
                          }
                          placeholder="Discord Username"
                          style={{
                            width: "100%",
                            padding: "8px 12px",
                            paddingInline: "unset",
                            margin: "0 0 8px",
                            textAlign: "center",
                            backgroundColor: "#002952",
                            border: "none",
                            borderRadius: "8px",
                            color: "#FFD60A",
                            marginBottom: "8px",
                          }}
                        />
                        <input
                          type="text"
                          // Add a default value if socialLinks.twitter is undefined
                          value={socialLinks.twitter || ''}
                          onChange={(e) =>
                            setSocialLinks((prev) => ({
                              ...prev,
                              twitter: e.target.value,
                            }))
                          }
                          placeholder="Twitter Handle"
                          style={{
                            width: "100%",
                            padding: "8px 12px",
                            paddingInline: "unset",
                            margin: "0 0 8px",
                            textAlign: "center",
                            backgroundColor: "#002952",
                            border: "none",
                            borderRadius: "8px",
                            color: "#FFD60A",
                          }}
                        />
                      </div>
                      <button
                        onClick={updateSocialLinks}
                        style={{
                          width: "100%",
                          padding: "10px",
                          backgroundColor: "#4CAF50",
                          color: "#fff",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "14px",
                          marginTop: "10px",
                        }}
                      >
                        Save Social Links
                      </button>
                    </div>
                    {/* Addresses Section */}
                    <div style={{ marginBottom: "20px" }}>
                      {/* Payment Address */}
                      <div style={{ marginBottom: "12px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "4px",
                          }}
                        >
                          <span style={{ fontSize: "14px", color: "#999" }}>
                            Payment Address
                          </span>
                          <div
                            onClick={() => copyToClipboard(paymentAddress)}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              color: "#4CAF50",
                              fontSize: "12px",
                            }}
                          >
                            Copy
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#002952",
                            padding: "8px 12px",
                            borderRadius: "8px",
                            fontSize: "14px",
                          }}
                        >
                          {truncateAddress(paymentAddress)}
                        </div>
                      </div>

                      {/* Ordinals Address */}
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "4px",
                          }}
                        >
                          <span style={{ fontSize: "14px", color: "#999" }}>
                            Ordinals Address
                          </span>
                          <div
                            onClick={() => copyToClipboard(ordinalsAddress)}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              color: "#4CAF50",
                              fontSize: "12px",
                            }}
                          >
                            Copy
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#002952",
                            padding: "8px 12px",
                            borderRadius: "8px",
                            fontSize: "14px",
                          }}
                        >
                          {truncateAddress(ordinalsAddress)}
                        </div>
                      </div>
                    </div>

                    {/* Disconnect Button */}
                    <button
                      onClick={disconnectWallet}
                      style={{
                        width: "100%",
                        padding: "10px",
                        backgroundColor: "#FF6347",
                        color: "#fff",
                        border: "none",
                        borderRadius: "8px",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "bold",
                        transition: "background-color 0.2s",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#FF4500";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "#FF6347";
                      }}
                    >
                      Disconnect Wallet
                    </button>
                  </div>
                </div>

                {/* Add this CSS for the profile hover effect */}
                <style>{`
  .profile-container:hover .profile-dropdown {
    visibility: visible !important;
    opacity: 1 !important;
  }
`}</style>

                {/* Wallet Management Button with Dropdown */}
                <div
                  className="wallet-nav-button"
                  style={{ position: "relative" }}
                >
                  <button
                    style={{
                      padding: "15px",
                      backgroundColor: "#001D3D",
                      color: "#FFD60A",
                      border: "none",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.transform = "translateX(10px)";
                      e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.2)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.transform = "translateX(0)";
                      e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
                    }}
                  >
                    <Wallet size={24} />
                  </button>

                  {/* Hover Dropdown */}
                  <div
                    className="wallet-hover-menu"
                    style={{
                      position: "absolute",
                      left: "70px",
                      top: "90%",
                      transform: "translateY(-8%)",
                      backgroundColor: "#001D3D",
                      padding: "20px",
                      borderRadius: "15px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
                      minWidth: "300px",
                      visibility: "hidden",
                      opacity: 0,
                      transition: "all 0.2s ease",
                      zIndex: 1000,
                    }}
                  >
                    {/* Connected Wallets Section */}
                    <div
                      style={{
                        marginBottom: "15px",
                        borderBottom: "1px solid #002952",
                        paddingBottom: "10px",
                      }}
                    >
                      <h3 style={{ color: "#FFD60A", marginBottom: "10px" }}>
                        Connected Wallets
                      </h3>
                      {connectedWallets.map((wallet) => (
                        <div
                          key={wallet.id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px",
                            backgroundColor:
                              activeWallet?.id === wallet.id
                                ? "#002952"
                                : "transparent",
                            borderRadius: "8px",
                            marginBottom: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => switchWallet(wallet)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Wallet size={16} color="#FFD60A" />
                            <span style={{ color: "#FFD60A" }}>
                              {truncateAddress(wallet.ordinalsAddress)}
                            </span>
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              removeWallet(wallet);
                            }}
                            style={{
                              background: "none",
                              border: "none",
                              color: "#FF6347",
                              cursor: "pointer",
                              padding: "5px",
                            }}
                          >
                            <X size={16} />
                          </button>
                        </div>
                      ))}
                    </div>

                    {/* Connect New Wallet Section */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <button
                        onClick={connectXverse}
                        style={{
                          padding: "10px",
                          backgroundColor: "transparent",
                          color: "#4CAF50",
                          border: "2px solid #4CAF50",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Plus size={14} />
                        Add Xverse Wallet
                      </button>

                      <button
                        onClick={connectUnisat}
                        style={{
                          padding: "10px",
                          backgroundColor: "transparent",
                          color: "#FFD60A",
                          border: "2px solid #FFD60A",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Plus size={14} />
                        Add Unisat Wallet
                      </button>

                      {/* Manual Address Input */}
                      <div
                        style={{
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <input
                          type="text"
                          value={manualAddress}
                          onChange={(e) => setManualAddress(e.target.value)}
                          placeholder="Enter BTC Address"
                          style={{
                            padding: "10px",
                            width: "100%",
                            backgroundColor: "transparent",
                            border: "1px solid #FFD60A", // Thinner border
                            borderRadius: "8px",
                            color: "#FFD60A",
                            fontSize: "14px",
                            margin: "0 0 8px",
                            textAlign: "center",
                            paddingInline: "unset",
                            outline: "none", // Remove default focus outline
                            boxSizing: "border-box", // Include padding in width calculation
                          }}
                        />
                        <button
                          onClick={handleManualAddressSubmit}
                          style={{
                            width: "100%",
                            padding: "10px",
                            backgroundColor: "transparent",
                            color: "#4CAF50",
                            border: "1px solid #4CAF50",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "8px",
                            transition: "all 0.2s ease",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor =
                              "rgba(76, 175, 80, 0.1)";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor =
                              "transparent";
                          }}
                        >
                          <Plus size={14} />
                          Add Manual Address
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Add CSS for hover effect */}
                <style>{`
  .wallet-nav-button:hover .wallet-hover-menu {
    visibility: visible !important;
    opacity: 1 !important;
  }
`}</style>

                {/* Add this right after the Wallet Selector component */}
                {showAddWallet && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1100,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#001D3D",
                        borderRadius: "20px",
                        padding: "30px",
                        width: "400px",
                        boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <h2
                        style={{
                          color: "#FFD60A",
                          textAlign: "center",
                          marginBottom: "30px",
                          fontSize: "24px",
                        }}
                      >
                        Add New Wallet
                      </h2>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <button
                          onClick={connectXverse}
                          style={{
                            padding: "15px",
                            backgroundColor: "transparent",
                            color: "#4CAF50",
                            border: "2px solid #4CAF50",
                            borderRadius: "25px",
                            cursor: "pointer",
                            fontSize: "16px",
                            width: "100%",
                          }}
                        >
                          Connect with Xverse
                        </button>

                        <button
                          onClick={connectUnisat}
                          style={{
                            padding: "15px",
                            backgroundColor: "transparent",
                            color: "#FFD60A",
                            border: "2px solid #FFD60A",
                            borderRadius: "25px",
                            cursor: "pointer",
                            fontSize: "16px",
                            width: "100%",
                          }}
                        >
                          Connect with Unisat
                        </button>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "10px 0",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              height: "1px",
                              backgroundColor: "#FFD60A",
                              opacity: 0.2,
                            }}
                          />
                          <span
                            style={{
                              margin: "0 15px",
                              color: "#FFD60A",
                              opacity: 0.6,
                            }}
                          >
                            or
                          </span>
                          <div
                            style={{
                              flex: 1,
                              height: "1px",
                              backgroundColor: "#FFD60A",
                              opacity: 0.2,
                            }}
                          />
                        </div>

                        <input
                          type="text"
                          value={manualAddress}
                          onChange={(e) => setManualAddress(e.target.value)}
                          placeholder="Enter BTC Address"
                          style={{
                            padding: "15px",
                            width: "100%",
                            backgroundColor: "transparent",
                            border: "2px solid #FFD60A",
                            borderRadius: "25px",
                            color: "#FFD60A",
                            fontSize: "16px",
                            outline: "none",
                            textAlign: "center",
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <button
                            onClick={handleManualAddressSubmit}
                            style={{
                              flex: 1,
                              padding: "15px",
                              backgroundColor: "transparent",
                              color: "#4CAF50",
                              border: "2px solid #4CAF50",
                              borderRadius: "25px",
                              cursor: "pointer",
                              fontSize: "16px",
                            }}
                          >
                            Add Wallet
                          </button>
                          <button
                            onClick={() => {
                              setShowAddWallet(false);
                              setManualAddress("");
                            }}
                            style={{
                              flex: 1,
                              padding: "15px",
                              backgroundColor: "transparent",
                              color: "#FF6347",
                              border: "2px solid #FF6347",
                              borderRadius: "25px",
                              cursor: "pointer",
                              fontSize: "16px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Navigation Buttons */}
                {navigationItems.map(({ id, label, icon: Icon, color }) => (
                  <div
                    key={id}
                    style={{ position: "relative" }}
                    onMouseEnter={(e) => {
                      const tooltip = document.getElementById(`tooltip-${id}`);
                      if (tooltip) {
                        tooltip.style.opacity = "1";
                        tooltip.style.visibility = "visible";
                      }
                    }}
                    onMouseLeave={(e) => {
                      const tooltip = document.getElementById(`tooltip-${id}`);
                      if (tooltip) {
                        tooltip.style.opacity = "0";
                        tooltip.style.visibility = "hidden";
                      }
                    }}
                  >
                    <button
                      onClick={() => setActiveSection(id)}
                      style={{
                        padding: "15px",
                        backgroundColor:
                          activeSection === id ? color : "#001D3D",
                        color: "#FFD60A",
                        border: "none",
                        borderRadius: "10px",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.transform = "translateX(10px)";
                        e.target.style.boxShadow =
                          "0 6px 8px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.transform = "translateX(0)";
                        e.target.style.boxShadow =
                          "0 4px 6px rgba(0, 0, 0, 0.1)";
                      }}
                    >
                      <Icon size={24} />
                    </button>
                    <div
                      id={`tooltip-${id}`}
                      style={{
                        position: "absolute",
                        left: "70px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        color: "#FFD60A",
                        padding: "8px 12px",
                        borderRadius: "6px",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        opacity: 0,
                        visibility: "hidden",
                        transition: "all 0.2s ease",
                        zIndex: 20,
                      }}
                    >
                      {label}
                    </div>
                  </div>
                ))}
              </div>

              {/* CSS for profile hover effect */}
              <style>{`
  .profile-container:hover > div {
    visibility: visible !important;
    opacity: 1 !important;
  }
`}</style>
            </div>
          )}
        </>
      )}

      {showSetPassword && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1100,
          }}
        >
          <div
            style={{
              backgroundColor: "#001D3D",
              padding: "30px",
              borderRadius: "20px",
              width: "400px",
            }}
          >
            <h3 style={{ color: "#FFD60A", marginBottom: "20px" }}>
              Set Your Password
            </h3>
            <p style={{ color: "#FFD60A", marginBottom: "20px" }}>
              Please set a password for your account to use for future logins.
            </p>
            <input
              type="password"
              placeholder="Enter Password"
              value={passwordData.password}
              onChange={(e) =>
                setPasswordData({ ...passwordData, password: e.target.value })
              }
              style={{
                width: "100%",
                padding: "15px",
                marginBottom: "10px",
                textAlign: "center",
                backgroundColor: "transparent",
                border: "2px solid #FFD60A",
                borderRadius: "25px",
                color: "#FFD60A",
              }}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={passwordData.confirmPassword}
              onChange={(e) =>
                setPasswordData({
                  ...passwordData,
                  confirmPassword: e.target.value,
                })
              }
              style={{
                width: "100%",
                padding: "15px",
                marginBottom: "20px",
                textAlign: "center",
                backgroundColor: "transparent",
                border: "2px solid #FFD60A",
                borderRadius: "25px",
                color: "#FFD60A",
              }}
            />
            <button
              onClick={handleSetPassword}
              style={{
                width: "100%",
                padding: "15px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "25px",
                cursor: "pointer",
              }}
            >
              Create Account
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Portfolio;
